import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import * as Constants from "../util/Constant";

const LoginHeader = (props) => {

    let lang = localStorage.getItem(Constants.LANG);
    const [language, setLanguage] = useState(lang == null ? 'en' : lang);

    //Calling t and i18n method from useTranslation hook
    const {t, i18n} = useTranslation();


    //Creating a method to change the language onChnage from select box
    const changeLanguage = lng => {

        localStorage.setItem(Constants.LANG, lng);
        setLanguage(lng);
        i18n.changeLanguage(lng);
        // window.location.reload();

    };

    const hrefToLogin = () => {

    };


    return (

        <header className="header">
            <div className="row">
                {/* <div className="col-auto ml-3 px-0">
                    <button className="btn-circle btn-link back-btn" type="button">
                        <span onClick={hrefToLogin} className="material-icons">arrow_back</span>
                    </button>
                </div> */}
                <div className="text-left col-6 align-self-center">
                </div>

                <div className="ml-auto col-auto pl-0 align-self-center">
                    <button className="btn-circle lan-select-btn" type="button" data-toggle="dropdown">
                        <span>{language === 'si' ? 'සිං' : language === 'ta' ? 'தா' : 'En'}</span>
                    </button>
                    <div
                        className="mt-3 mr-2 ml-0 dropdown-menu dropdown-menu-left dropdown-language text-center">
                        <button type="button" className="mb-2 btn-circle btn-outline-primary" id="en"
                                onClick={() => changeLanguage('en')}>En
                        </button>
                        <button type="button" className="mb-2 btn-circle btn-outline-primary" id="si"
                                onClick={() => changeLanguage('si')}>සිං
                        </button>
                        <button type="button" className="mb-2 btn-circle btn-outline-primary" id="ta"
                                onClick={() => changeLanguage('ta')}>தா
                        </button>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default LoginHeader