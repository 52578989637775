import React, { useEffect, useState } from 'react';
import * as Constants from "../util/Constant";
// import {useNavigate} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

let CryptoJS = require("crypto-js");


const Home = () => {

    const [login, setLogin] = useState(null);
    const history = useHistory();
    const { t } = useTranslation();
    // const navigate = useNavigate();

    const [title, setTitle] = useState({});
    const [qr, setQR] = useState(null);
    let lang = localStorage.getItem(Constants.LANG);
    const [language, setLanguage] = useState(lang == null ? 'en' : lang);

    //get base url
    useEffect(() => {
        fetch(Constants.CONFIG_TITLE_PATH).then(response => {
            response.json().then(title => {
                setTitle(title);
            }).catch(error => {
                console.log("Couldn't find the configurations file");
                console.error(error);
            })
        });

        let prof = localStorage.getItem(Constants.USER_PROFILE);
        if (prof !== null) {
            history.push({
                pathname: '/get-token'
            });
            return;
        }
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    // save configuration data on localStorage
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setLogin(decrypt.LOGIN);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setLogin(decrypt.LOGIN);
        }
    }, [Home]);

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        localStorage.setItem(Constants.LANG, lng);
    };

    const navigate = page => {
        history.push({
            pathname: page
        });
    };

    // --------------slide show---------------------
    const slideImages = [
        {
            url: 'img/slider01.png',
            caption: t('home.slide1.title'),
            desc: t('home.slide1.desc'),
        },
        {
            url: 'img/slider02.png',
            caption: t('home.slide2.title'),
            desc: t('home.slide2.desc'),

        },
        {
            url: 'img/slider03.png',
            caption: t('home.slide3.title'),
            desc: t('home.slide3.desc'),

        }
    ];

    return (
        <div style={{ overflowX: 'hidden' }}>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8 pr-0">

                        <main className="flex-shrink-1 d-flex align-content-between flex-wrap min-vh-100 py-5 px-5">


                            <header className="header position-relative px-3 py-3">
                                <div className="row">
                                    <div className="col-2 align-self-center text-left px-0">
                                        <div className="avatar avatar-40 shadow-sm rounded-circle ml-2">
                                            <figure className="m-0 background"
                                                style={{ backgroundImage: `url('${title.logo}')` }}>
                                                <img src={title.logo} className='header-logo' />
                                            </figure>
                                        </div>
                                    </div>


                                    <div className="col-10 align-self-center text-right px-0">
                                        <button className="btn bg-skip-tour-light text-white text-capitalize px-3 mx-1 lan-select-btn"
                                            type="button" data-toggle="dropdown">
                                            <i className="material-icons">translate</i><span> language</span>
                                        </button>
                                        <div
                                            className="mt-3 mr-2 ml-0 dropdown-menu dropdown-menu-right dropdown-language text-center">
                                            <button type="button" className="mb-2 btn-circle btn-outline-primary"
                                                id="en"
                                                onClick={() => changeLanguage('en')}>En
                                            </button>
                                            <button type="button" className="mb-2 btn-circle btn-outline-primary"
                                                id="si"
                                                onClick={() => changeLanguage('si')}>සිං
                                            </button>
                                            <button type="button" className="mb-2 btn-circle btn-outline-primary"
                                                id="ta"
                                                onClick={() => changeLanguage('ta')}>தா
                                            </button>
                                        </div>

                                    </div>

                                </div>
                            </header>


                            <div className="container">
                                <div className="row mx-0">
                                    <div className="col-1 col-md-1 col-lg-2" />
                                    <div className="col-10 col-md-10 col-lg-8">

                                        {/* -----------slide show------------------- */}

                                        <div className="slide-container">

                                            <Slide>
                                                {slideImages.map((slideImage, index) => (
                                                    <div className="" key={index}>
                                                        <div className="carousel-item" style={{ display: 'block' }}>
                                                            <img src={slideImage.url} className="d-block  mx-auto  w-65"
                                                                alt="..." />
                                                            <div className="carousel-caption  text-white">
                                                                <h6 className="fw600">{slideImage.caption}</h6>
                                                                <p className="fw400">{slideImage.desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}
                                            </Slide>
                                        </div>

                                        <ol className="carousel-indicators">
                                            <li data-bs-target="#landingCarousel" data-bs-slide-to="0"
                                                className="" />
                                            <li data-bs-target="#landingCarousel" data-bs-slide-to="1" />
                                            <li data-bs-target="#landingCarousel" data-bs-slide-to="2" />
                                        </ol>


                                    </div>
                                    <div className="col-1 col-md-1 col-lg-2" />
                                </div>
                            </div>
                            {login === 'true' ?
                                <div className="container">
                                    <div className="row mx-0">
                                        <div className="col-12 mx-0">
                                            <div className="text-center px-0 mb-5 text-white slide-up">
                                                <h6 className="fw600 "><a href="#"
                                                    className="text-ts-blue text-white">
                                                    {t('home.qr.scan')}
                                                </a></h6>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <p className="fw300">
                                                    <a href="https://www.ts.lk/" target='_blank'
                                                        className="text-ts-blue">{t('home.poweredBy')}</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container">
                                    <div className="row mx-0">
                                        <div className="col-12 mx-0">
                                            <div className="text-center px-0 mb-5 text-white slide-up">
                                                <button onClick={() => navigate('/login')}
                                                    className="btn btn-light px-5">{t('home.login')}</button>
                                                <div className="fw400 mt-3" style={{ color: '#ffffff94' }}>{t('home.register.pretext')}
                                                    <div style={{ 'cursor': 'pointer', 'display': 'inline' }}
                                                        className="text-white"
                                                        onClick={() => navigate('/register')}>
                                                        <span className="fw600"> {t('home.signUp')}</span>
                                                    </div>
                                                </div>
                                                <p style={{ color: '#ffffff94' }}>{t('home.postText')}</p>
                                                <p className="fw300">
                                                    <a href="https://www.ts.lk/" target='_blank' className="text-ts-blue">
                                                        {t('home.footer')}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </main>
                    </div>


                    <div
                        className="col-sm-12 col-md-12 col-lg-6 col-xl-4 bg-FAFCFF d-flex align-items-center flex-wrap min-vh-100 text-center px-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 px-3 pt-4 pb-1">
                                    <h2 className="pt-3 pb-5 px-3 fw800"> {t('home.section2.title')}</h2>
                                    <h6 className="fw600"> {t('home.section2.desc')}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mx-auto">

                                    <div className="card rounded-top bg-purple w-65 shadow-default">
                                        <div className="card-header">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-2 ">
                                                    <div
                                                        className="avatar avatar-30 border-0 bg-white-light rounded-circle" />
                                                </div>
                                                <div className="col-8 text-left">
                                                    <div className="h-5 w-65 bg-white-light mb-1" />
                                                    <div className="h-5 w-55 bg-white-light mb-1" />
                                                </div>
                                                <div className="col-2 text-center">
                                                    <div
                                                        className="avatar avatar-10 border-0 bg-white-light rounded-circle" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body border-0 rounded-top bg-white-light border-right pb-5"
                                            style={{ height: "30vh" }}>
                                            <div className="row d-flex justify-content-around ">
                                                <div className="col-5 rounded bg-white-light text-right mx-1"
                                                    style={{ height: "10vh" }}>
                                                    <div
                                                        className="avatar avatar-10 border-0 bg-white-light rounded-circle" />
                                                </div>
                                                <div className="col-5 rounded bg-white-light text-right mx-1"
                                                    style={{ height: "10vh" }}>
                                                    <div
                                                        className="avatar avatar-10 border-0 bg-white-light rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mx-auto mt-4">
                                                    <button className="btn bg-white-light w-70"
                                                        style={{ height: "5vh" }} />
                                                </div>
                                            </div>

                                        </div>

                                        <div
                                            className="col-auto d-flex position-absolute bg-white rounded text-black-50 justify-content-between py-1 onleftmiddle shadow">
                                            <div
                                                className="spinner-grow avatar avatar-20 border-0 bg-danger-light rounded-circle" />
                                            <span className="fw600">{t('home.notification')}</span></div>

                                        <div
                                            className="col-auto d-flex position-absolute bg-white rounded text-black-50 justify-content-center py-1 ontopright shadow">
                                            <div
                                                className="spinner-grow avatar avatar-20 border-0 bg-success-light rounded-circle" />
                                            <span className="fw600">{t('home.notification')}</span></div>

                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 py-5 " />
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </div>

    );
};

export default Home;
