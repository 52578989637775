import React, {useEffect, useRef, useState} from 'react';
import * as Constants from '../util/Constant';
import {authentication} from '../config/firebase';
import {RecaptchaVerifier, signInWithPhoneNumber} from 'firebase/auth';
import Swal from 'sweetalert2';
import {useTranslation} from "react-i18next";
import OtpInput from 'react-otp-input';
import axios from "axios";
import {useHistory, useLocation} from 'react-router-dom';

const GetTokenModalGetMobileNo = ({setShowGetTokenModal, getToken, setVerifiedMobileNo, gateway, selectedBranch, selectedService}) => {

    const [mobileNoVerifyScreen, setMobileNoVerifyScreen] = useState(false);
    const [mobileNo, setMobileNo] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isMobileNoVerified, setIsMobileNoVerified] = useState(false);
    const [otp, setOtp] = useState("");

    const [isLoading2, setIsLoading2] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const { t } = useTranslation();
    const ref = useRef(null);

    const [minutes, setMinutes] = useState(Constants.OTP_TRY_AGAIN_MINUTES);
    const [seconds, setSeconds] = useState(Constants.OTP_TRY_AGAIN_SECONDS);

    // focus OTP input field
    useEffect(() => {
        if (mobileNoVerifyScreen) {
            ref.current && ref.current.focus();
        }
    }, [mobileNoVerifyScreen]);

    // countdown
    useEffect(() => {
        if (mobileNoVerifyScreen === true) {
            let myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval)
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
            }, 1000)
            return () => {
                clearInterval(myInterval);
            }
        }
    });

    // when enter otp, call verifyOTP function
    useEffect(() => {
        if (otp.length === 6) {
            verifyOTP();
        }
    }, [otp])

    // call getToken function after verify mobile no
    useEffect(() => {
        if (isMobileNoVerified) {
            getToken();
        }
    }, [isMobileNoVerified])

    // send otp - function
    function sendOtp() {
        if (mobileNo.length < 9) {
            setErrorMsg(t('home.modal.alert4'));
        } else {
            setErrorMsg('');

            setMobileNoVerifyScreen(true);

            // loader
            Swal.fire({
                position: 'center',
                text: t('home.modal.loading'),
                allowOutsideClick: false,
                width: "200px"
            });
            Swal.showLoading();

            if (window.recaptchaVerifier !== undefined) {
                window.recaptchaVerifier.clear();

                if (document.getElementById("recaptcha-container") !== null)
                    document.getElementById("recaptcha-container").remove();

            }
            let div = document.createElement("div");
            div.id = "recaptcha-container";

            let element = document.getElementById("div1");
            element.appendChild(div);

            generateRecaptcha();
            let countryCode = '+94';
            let phoneNumber = countryCode + mobileNo
            let appVerifier = window.recaptchaVerifier;
            generateOTP(mobileNo);
            // signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
            //     .then(confirmationResult => {
            //         window.confirmationResult = confirmationResult;
            //         Swal.close();
            //         setMobileNoVerifyScreen(true);
            //     }).catch((error) => {
            //         console.log('error : ', error);
            //         if ((error + "").includes('auth/quota-exceeded')) {
            //             Swal.fire('', t('signUp.alert1'), 'error');
            //
            //         } else if ((error + "").includes('auth/too-many-requests')) {
            //             Swal.fire('', t('signUp.alert2'), 'error');
            //
            //         } else if ((error + "").includes('auth/invalid-phone-number')) {
            //             Swal.fire('', t('signUp.alert3'), 'error');
            //
            //         } else if ((error + "").includes('reCAPTCHA has already been rendered in this element')) {
            //             Swal.fire('', t('signUp.alert4'), 'error');
            //
            //         } else {
            //             Swal.fire('', t('signUp.alert5'), 'error');
            //         }
            //     })
        }
    }

    const generateOTP = (mobile) => {
        let phone = Constants.MOBILE_PREFIX + mobile;
        setIsLoading2(true);

        axios({
            method: 'POST',
            url: `${gateway}/sms/add-otp`,
            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVU0VSIiwiVVVJRCI6IjAxYThhMjllLWQ3YTgtNGUxOC1iYmQ3LTY4OGQyNjYxOGJmOSIsImlzcyI6InRzLWdhdGV3YXkiLCJpYXQiOjE3MTg4NjI1MzQsImV4cCI6MjYzMDcyMDAwMH0.g7qr_oVcEg9rCKi_lQND0ojORe2ZJTcR3D7cTIUhaQQ'
            },
            data: {
                "number": phone
            }
        }).then((response) => {
            console.log("Res: ", response)
            console.log("Phone: ", phone)
            if (response.data.data !== null) {
                localStorage.setItem(Constants.MOBILE_PREFIX, phone);
                localStorage.setItem("otp_auth", response.data.data);
                Swal.close();
                setIsLoading2(false)
            } else {
                Swal.fire('', t('login.alert1'), 'error');
                setIsLoading2(false)
            }
        }).catch((error) => {
            setIsLoading2(false)
            console.log(error);
            Swal.fire('', error.response.data.data, 'error');
        })
    };

    // generate recaptcha - function
    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
            }
        }, authentication);
    }

    // resendOtp - function
    const resendOtp = () => {

        // disable try again button
        setMinutes(Constants.OTP_TRY_AGAIN_MINUTES);
        setSeconds(Constants.OTP_TRY_AGAIN_SECONDS);

        ref.current && ref.current.focus();

        let countryCode = '+94';
        let phoneNumber = countryCode + mobileNo
        let appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
            })
            .catch((error) => {
                if ((error + "").includes('auth/quota-exceeded')) {
                    Swal.fire('', t('signUp.alert1'), 'error');

                } else if ((error + "").includes('auth/too-many-requests')) {
                    Swal.fire('', t('signUp.alert2'), 'error');

                } else if ((error + "").includes('auth/invalid-phone-number')) {
                    Swal.fire('', t('signUp.alert3'), 'error');

                } else if ((error + "").includes('reCAPTCHA has already been rendered in this element')) {
                    Swal.fire('', t('signUp.alert4'), 'error');

                } else {
                    Swal.fire('', t('signUp.alert5'), 'error');
                }

            });

    };

    // verity otp
    const verifyOTP = () => {

        // loader
        if (otp.length === 6) {

            // loader
            Swal.fire({
                position: 'center',
                text: t('home.modal.loading'),
                allowOutsideClick: false,
                width: "200px"
            });
            Swal.showLoading();

            axios({
                method: 'POST',
                url: `${gateway}/sms/authenticate-otp`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("otp_auth")}`
                },
                data: {
                    otp: otp
                }
            }).then((response) => {
                Swal.hideLoading();
                Swal.close();
                setIsMobileNoVerified(true)
            })
                .catch((error) => {
                    Swal.hideLoading();
                    setIsLoading2(false);
                    if (error.response !== undefined && error.response !== null && error.response.data !== undefined && error.response.data !== null)
                        if (error.response.data.data !== undefined) {
                            Swal.fire('', error.response.data.data, 'error')
                        } else {
                            Swal.fire('', error.response.data.error, 'error')
                            history.push({
                                pathname: '/login'
                            });
                        }
                    else Swal.fire('', 'Something went wrong!', 'error')
                });
        } else {
            return Swal.fire('', t('loginOtp.alert2'), 'error');
        }
    };

    //skip function
    function skip() {
        Swal.fire({
            icon: 'warning',
            text: t('home.skip'),
            confirmButtonText: t('home.modal.next'),
            showCancelButton: true,
            allowOutsideClick: false,
        }).then((swalResult) => {
            if (swalResult.isConfirmed) {
                getToken();
            }
        })
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('home.modal.dismiss'),
            width: '400px',
        })
    }

    return (
        <div>
            {/* <div id='recaptcha-container' style={{ display: 'none' }}></div> */}
            <div id="div1" style={{ display: 'none' }} />

            {mobileNoVerifyScreen === false ? (
                <div className="jumbotron">
                    <div className="row px-3 justify-content-center">
                        <div className="col-12 px-0 mx-0">
                            <h6 className="font-weight-bold">{t('home.token.description')}</h6>
                        </div>
                    </div>

                    <div className="row justify-content-center mx-0">

                        <div className="col-auto px-0 mx-0">
                            <form>
                                <div className="input-group input-group-sm mb-3 form-group float-label position-relative">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">{Constants.MOBILE_PREFIX}</span>
                                    </div>
                                    <input
                                        type="text" required
                                        maxLength="9"
                                        className="form-control pl-1"
                                        placeholder={'7xxxxxxxx'}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setMobileNo(e.target.value)
                                        }}
                                    />
                                    {/* <label className="form-control-label pl-5">{t('home.token.mobile')}</label> */}
                                </div>
                                <p style={{ color: "red" }}>{errorMsg}</p>
                            </form>
                        </div>
                        <div className="col-auto my-3">

                            <button type="button" className="mb-3 btn btn-primary text-uppercase px-5 display-block"
                                onClick={sendOtp}>{t('home.token.next')}
                            </button>
                            <div className='ahref display-inline mr-3'
                                onClick={() => {
                                    setShowGetTokenModal(prev => !prev);
                                }}>Cancel</div>
                            <div className='display-inline primary-text'>|</div>
                            <div className='ahref display-inline ml-3' onClick={skip}>{t('home.token.skip')}</div>

                        </div>
                    </div>

                </div>
            ) : (
                <div className="jumbotron">
                    <div className="row px-3 justify-content-center">
                        <div className="col-12 mb-2 px-0 mx-0">
                            <h6 className="font-weight-bold">{t('home.token.verify')}</h6>
                        </div>
                    </div>

                    <div className="row justify-content-center mx-0">
                        <div className="col-12 align-self-center mb-4">
                            <div className="row px-0 justify-content-center">
                                <form>
                                    <div className="input-group input-group-otp mb-3 pt-0 form-group float-label position-relative"
                                        data-group-name="digits">

                                        <OtpInput
                                            numInputs={6}
                                            value={otp}
                                            onChange={setOtp}
                                            shouldAutoFocus
                                            isInputNum
                                            focusStyle={{
                                                outline: 'none',
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
                                            }}
                                            inputStyle={{
                                                width: "30px",
                                                height: "30px",
                                                margin: "0 7px",
                                                fontSize: "28px",
                                                border: 0,
                                                color: '#495057',
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                                                backgroundColor: '#f4f7fd'
                                            }}
                                        />
                                        
                                    </div>
                                    <p>{t('home.token.did')}<br />

                                        <a className={minutes === 0 && seconds === 0 ? '' : 'disable-btn'} onClick={() => resendOtp()}>
                                            {t('home.token.tryAgain')}
                                        </a>

                                        {minutes === 0 && seconds === 0
                                            ? null
                                            : <a className={minutes === 0 && seconds === 0 ? '' : 'disable-btn'}> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</a>
                                        }

                                    </p>
                                    <button type="button" className="mb-3 btn btn-primary px-5"
                                        onClick={verifyOTP}>{t('home.token.token')}</button>
                                    <div className='ahref'
                                        onClick={() => {
                                            setShowGetTokenModal(prev => !prev);
                                        }}>{t('home.token.cancel')}</div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default GetTokenModalGetMobileNo