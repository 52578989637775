import React, {useEffect, useState} from 'react'
import * as Constants from "../util/Constant";
const Loading = () => {
    const [title, setTitle] = useState({});

    useEffect(() => {
        fetch(Constants.CONFIG_TITLE_PATH).then(response => {
            response.json().then(title => {
                setTitle(title);
            }).catch(error => {
                console.error(error);
            })
        });
    }, [Loading]);


    return (
        <div className="container-fluid h-100 loader-display">
            <div className="row h-100">
                <div className="align-self-center col">
                    <div className="logo-loading">
                        <div className=" mb-4 rounded-circle">
                            <img src={title.loaderlogo} alt="" className="w-100"/>
                        </div>
                        <h4 className="text-primary py-0 my-0 loader-text">{title.title1}</h4>
                        <h5 className="text-primary loader-text">Appointment Booking</h5>
                        <div className="loader-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading