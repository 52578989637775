//Yasintha Gunathilake
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Header from "../components/Header";
import { useHistory } from 'react-router-dom';
import * as Constants from "../util/Constant";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { async } from "@firebase/util";
import Footer from "../components/Footer";

let CryptoJS = require("crypto-js");

const EditProfile = () => {

    const history = useHistory();

    const { t } = useTranslation();
    const [baseUrl, setBaseUrl] = useState('');

    const [id, setId] = useState(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [nic, setNic] = useState('')//NIC,PASSPORT or DRIVING LICENCE
    const [mobile, setMobile] = useState(null)
    const [contactMethod, setContactMethod] = useState('')

    const [errorMessage1, setErrorMessage1] = useState("");
    const [errorMessage2, setErrorMessage2] = useState("");
    const [errorMessage3, setErrorMessage3] = useState("");
    const [errorMessage4, setErrorMessage4] = useState("");
    const [errorMessage5, setErrorMessage5] = useState("");
    const [errorMessage6, setErrorMessage6] = useState("");

    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
        }
    }, [EditProfile]);

    useEffect(() => {
        if (baseUrl.length > 0) {
            getUserData();
        }
    }, [baseUrl])

    const validate = () => {
        let errorMessage_1 = "";
        let errorMessage_2 = "";
        let errorMessage_3 = "";
        let errorMessage_4 = "";
        let errorMessage_5 = "";
        let errorMessage_6 = "";

        if (firstName === "") {
            errorMessage_1 = t('signUp.error1');
            setErrorMessage1(errorMessage_1);
            return false;
        }
        if (firstName !== "") {
            errorMessage_1 = "";
            setErrorMessage1(errorMessage_1);
            return true;
        }
        if (lastName === "") {
            errorMessage_2 = t('signUp.error2');
            setErrorMessage2(errorMessage_2);
            return false;
        }
        if (lastName !== "") {
            errorMessage_2 = "";
            setErrorMessage2(errorMessage_2);
            return true;
        }
        if (email === "") {
            errorMessage_3 = t('signUp.error3');
            setErrorMessage3(errorMessage_3);
            return false;
        }
        if (email !== "") {
            errorMessage_3 = "";
            setErrorMessage3(errorMessage_3);
            return true;
        }
        if (mobile === "") {
            errorMessage_4 = t('signUp.error4');
            setErrorMessage4(errorMessage_4);
            return false;
        }
        if (mobile !== "") {
            errorMessage_4 = "";
            setErrorMessage4(errorMessage_4);
            return true;
        }
        if (mobile.charAt(0) === "0") {
            errorMessage_5 = t('signUp.error5');
            setErrorMessage5(errorMessage_5);
            return false;
        }
        if (mobile !== "") {
            errorMessage_5 = "";
            setErrorMessage5(errorMessage_5);
            return true;
        }
        if (mobile.length != 9) {
            errorMessage_6 = t('signUp.error6');
            setErrorMessage6(errorMessage_6);
            return false;
        }
        if (mobile.length !== "") {
            errorMessage_6 = "";
            setErrorMessage6(errorMessage_6);
            return true;
        }
        return true;
    };

    let userData = JSON.parse(localStorage.getItem(Constants.USER_PROFILE))

    const options = [
        {
            label: "Call",
            value: "Call",
        },
        {
            label: "Whatsapp",
            value: "WhatsApp",
        },
        {
            label: "Viber",
            value: "Viber",
        },
        {
            label: "Imo",
            value: "Imo",
        },
        {
            label: "Walk-in",
            value: "Walk-in",
        },
    ];

    // get user details - function
    const getUserData = () => {

        // loader
        Swal.fire({
            position: 'center',
            text: "Loading",
            allowOutsideClick: false,
            width: "200px"
        });
        Swal.showLoading();

        const userId = userData.id
        const userToken = userData.authToken

        const headers = {
            "X-AUTH-TOKEN": `${userToken}`,
            "Content-Type": "application/json",
        };

        if (baseUrl != null) {
            axios.post(baseUrl + Constants.USER + Constants.VERSION + Constants.USER_BY_ID + "/" + `${userId}`, null, { headers: headers }).then((response) => {
                Swal.close();

                let userData = response.data.data;

                setId(userData.id)
                setFirstName(userData.name)
                setLastName(userData.lastName)
                setEmail(userData.email)
                setNic(userData.nic)

                let phone = userData.mobile;
                let n = 3;
                phone = phone.substring(n)
                setMobile(phone)
                
                setContactMethod(userData.prefContact)
            }).catch((error) => {
                Swal.close();
                console.log("Error: ", error);
            })
        }
    }

    // update user - function
    const updateUserDetails = (e) => {
        e.preventDefault();
        const userToken = userData.authToken

        const headers = {
            "X-AUTH-TOKEN": `${userToken}`,
            "Content-Type": "application/json",
        };

        const isValidate = validate();
        // let phone = "+94" + mobile;

        let body = {
            id: id,
            name: firstName,
            lastName: lastName,
            email: email,
            nic: nic,
            mobile: mobile,
            prefContact: contactMethod
        }

        if (isValidate) {
            axios.post(baseUrl + Constants.USER + Constants.UPDATE, body, { headers: headers })
                .then((response) => {
                    Swal.fire(t('otp.alert3'), t('otp.alert4'), 'success');

                }).catch((error) => {
                    console.log("Error: ", error);
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        text: t('editProfile.alert1'),
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: t('editProfile.dismiss'),
                        width: '400px',
                    })
                })
        } else {
            console.log("Validate Error");
        }
    }

    // logout - fuction
    const logout = (e) => {
        e.preventDefault()

        const userId = userData.id
        const userToken = userData.authToken

        const headers = {
            "X-AUTH-TOKEN": `${userToken}`,
            "Content-Type": "application/json",
        };

        let body = {
            id: id,
            name: firstName,
            lastName: lastName,
            email: email,
            nic: nic,
            mobile: mobile,
            prefContact: contactMethod
        }

        axios.post(baseUrl + Constants.USER + Constants.LOGOUT + "/" + `${userId}`, body, { headers: headers }).then((response) => {
            if (response.data.data === true) {
                localStorage.clear();
                history.push('/')
            }


        }).catch((error) => {
            console.log("Logout Error: ", error);
        })
    }

    return (
        <div>
            <Header />

            {/* use to OTP */}
            <div id="recaptcha-container"></div>

            <div className="d-flex flex-column h-100">
                {/* <!-- screen loader --> */}
                {/* <!-- Begin page content --> */}
                <main className="flex-shrink-1 main d-flex flex-wrap">
                    {/* <!-- Fixed navbar --> */}

                    <div className="main-container vh-90 no-scroll">
                        {/* <!-- page content start --> */}

                        <form className="edit-profile-form">
                            <div className="container">
                                <h6 className="my-4 text-center"> {t("profile")}</h6>
                                <div className="card-body-scroller">
                                    <div className="row px-4 justify-content-center">
                                        <div className="col-6 px-1">
                                            <div className="card border-0">
                                                <div className="card-body py-2">
                                                    <div className="row align-items-center">
                                                        <div className="col align-self-center">
                                                            <div className="form-group float-label mb-1 pt-1 active">
                                                                {/* <!-- put active when field is not empty --> */}
                                                                <input
                                                                    type="text"
                                                                    className="form-control  pt-01 pb-0"

                                                                    value={firstName}
                                                                    onChange={(e) => setFirstName(e.target.value)}
                                                                />
                                                                <p style={{ color: "red" }}>{errorMessage1}</p>
                                                                {/* <!-- Email can be vaildate using is-valid above --> */}
                                                                <label className="form-control-label text-uppercase">
                                                                    {t("editProfile.firstName")}
                                                                </label>

                                                                {/* <!-- and with valid-feedback inside the className --> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 px-1">
                                            <div className="card border-0">
                                                <div className="card-body py-2">
                                                    <div className="row align-items-center">
                                                        <div className="col align-self-center">
                                                            <div className="form-group float-label mb-1 pt-1 active">
                                                                <input
                                                                    type="text"
                                                                    className="form-control  pt-1 pb-0"
                                                                    value={lastName}
                                                                    onChange={(e) => setLastName(e.target.value)}
                                                                />
                                                                <p style={{ color: "red" }}>{errorMessage2}</p>
                                                                <label className="form-control-label text-uppercase">
                                                                    {t("editProfile.lastName")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-4 py-1 justify-content-center">
                                        <div className="col-12 px-1">
                                            <div className="card border-0">
                                                <div className="card-body py-2">
                                                    <div className="row align-items-center">
                                                        <div className="col align-self-center">
                                                            <div className="form-group float-label mb-1 pt-1 active">
                                                                {/* <!-- put active when field is not empty --> */}
                                                                <input
                                                                    type="email"
                                                                    className="form-control  pt-1 pb-0"
                                                                    id="email"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                />
                                                                <p style={{ color: "red" }}>{errorMessage3}</p>
                                                                {/* <!-- Email can be vaildate using is-valid above --> */}
                                                                <label className="form-control-label text-uppercase">
                                                                    {t("editProfile.email")}
                                                                </label>
                                                                {/* <p className="form-text invalid-feedback">{t('email.error')}</p> */}
                                                                {/* <p className="form-text invalid-feedback">{t('errorMessage2')}</p> */}
                                                                {/* <!-- and with valid-feedback inside the className --> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-4 py-1 justify-content-center">
                                        <div className="col-12 px-1">
                                            <div className="card border-0">
                                                <div className="card-body py-2">
                                                    <div className="row align-items-center">
                                                        <div className="col align-self-center">
                                                            <div className="form-group float-label mb-1 pt-1 active">
                                                                {/* <!-- put active when field is not empty --> */}
                                                                <input
                                                                    type="text"
                                                                    className="form-control  pt-1 pb-0"
                                                                    id="nic"
                                                                    value={nic}
                                                                    onChange={(e) => setNic(e.target.value)}
                                                                />
                                                                {/* <!-- Email can be vaildate using is-valid above --> */}
                                                                <label className="form-control-label text-uppercase">
                                                                    {t("editProfile.nic")}
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-4 py-1 justify-content-center">
                                        <div className="col-12 px-1">
                                            <div className="card border-0">
                                                <div className="card-body py-2" style={{ height: '70px' }}>
                                                    <div className="row align-items-center">
                                                        <div className="col align-self-center">
                                                            <div className="input-group input-group-sm mb-3 form-group float-label active">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{marginRight: '5px'}}>+94</span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control pt-1 pb-0"
                                                                    id="nic"
                                                                    value={mobile}
                                                                    onChange={(e) => setMobile(e.target.value)}
                                                                    disabled
                                                                />



                                                                <p style={{ color: "red" }}>{errorMessage4}</p>
                                                                <p style={{ color: "red" }}>{errorMessage5}</p>
                                                                <p style={{ color: "red" }}>{errorMessage6}</p>
                                                                {/* <!-- Email can be vaildate using is-valid above --> */}
                                                                <label className="form-control-label text-uppercase">
                                                                    {t("editProfile.mobile")}
                                                                </label>
                                                                {/* <p className="form-text invalid-feedback">{t('nic.error')}</p> */}
                                                                {/* <!-- and with valid-feedback inside the className --> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-4 py-1 justify-content-center">
                                        <div className="col-12 px-1">
                                            <div className="card border-0">
                                                <div className="card-body py-2">
                                                    <div className="row align-items-center">

                                                        <div className="col align-self-center">
                                                            <div className="form-group float-label mb-1 pt-1 active">


                                                                <label className="form-control-label text-uppercase">
                                                                    {t("editProfile.contactMethod")}
                                                                </label>
                                                                <select
                                                                    className="form-control pt-1 pb-0"
                                                                    value={contactMethod}
                                                                    onChange={(e) => setContactMethod(e.target.value)}>
                                                                    {options.map((option) => (
                                                                        <option key={option.value}>
                                                                            {option.value}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-4 pt-4 pb-4 justify-content-center"
                                    style={{ marginBottom: '5px' }}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={(e) => updateUserDetails(e)}
                                    > {t("editProfile.submit")} </button>
                                    <button style={{ marginLeft: '5px' }}
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={(e) => logout(e)}
                                    >{t("editProfile.logout")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </main>
                <div className="alert alert-light mx-3 px-4 d-none" role="alert">
                    {/* <!-- Alert is here, change the icon and bg-color for different alerts. -->  */}
                    <button
                        type="button"
                        className="close text-danger"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true"></span>
                    </button>
                    <div className="row align-items-center">
                        <div className="col-2 px-0 align-self-center">
                            <div className="avatar avatar-40 border-0 bg-success rounded-circle text-light">
                                <i className="material-icons">check</i>
                            </div>
                        </div>
                        <div className="col-10 pl-0 align-self-center">
                            <h6 className="m-0">success</h6>
                        </div>
                    </div>
                </div>

                {/* <!-- footer--> */}
                <Footer />

            </div>
        </div>
    )
}

export default EditProfile