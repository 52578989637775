import React, {useEffect, useState} from 'react'
import {authentication} from '../config/firebase';
import {RecaptchaVerifier} from 'firebase/auth'
import {useHistory} from 'react-router-dom';
import Swal from 'sweetalert2'
import LoginHeader from "../components/LoginHeader";
import {useTranslation} from "react-i18next";
import * as Constants from "../util/Constant";
import Loading from "../components/Loading";
import axios from "axios";

let CryptoJS = require("crypto-js");
export default function Register(props, setLanguage) {

    const history = useHistory()

    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobile, setMobileNumber] = useState("");
    const [email, setEmailAddress] = useState("");
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const [baseUrl, setBaseUrl] = useState('');
    const [gateway, setGateway] = useState('');

    const details = [name, lastName, mobile, email]

    const [genToken, setGenToken] = useState(null);

    const [errorMessage1, setErrorMessage1] = useState("");
    const [errorMessage2, setErrorMessage2] = useState("");
    const [errorMessage3, setErrorMessage3] = useState("");
    const [errorMessage4, setErrorMessage4] = useState("");
    const [errorMessage5, setErrorMessage5] = useState("");
    const [errorMessage6, setErrorMessage6] = useState("");

    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('home.modal.dismiss'),
            // timer: 2500,
            width: '400px',
        })
    }

    useEffect(() => {

        let prof = localStorage.getItem(Constants.USER_PROFILE);
        if (prof !== null) {
            history.push({
                pathname: '/get-token'
            });
            return;
        }
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);

                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        }
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                    setGateway(decrypt.GATEWAY_URL);
                    setGenToken(decrypt.GEN_TOKEN);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
            setGateway(decrypt.GATEWAY_URL);
            setGenToken(decrypt.GEN_TOKEN);
        }
    }, [Register]);

    useEffect(() => {
        setIsLoading(false)
    }, []);

    // useEffect(() => {
    //     console.log("Token: ", genToken);
    // }, [genToken])


    const validate = () => {
        let errorMessage_1 = "";
        let errorMessage_2 = "";
        let errorMessage_3 = "";
        let errorMessage_4 = "";
        let errorMessage_5 = "";
        let errorMessage_6 = "";

        if (name === "") {
            errorMessage_1 = t('signUp.error1');
            setErrorMessage1(errorMessage_1);
            return false;
        } if (name !== "") {
            errorMessage_1 = "";
            setErrorMessage1(errorMessage_1);
        } if (lastName === "") {
            errorMessage_2 = t('signUp.error2');
            setErrorMessage2(errorMessage_2);
            return false;
        } if (lastName !== "") {
            errorMessage_2 = "";
            setErrorMessage2(errorMessage_2);
        } if (email === "") {
            errorMessage_3 = t('signUp.error3');
            setErrorMessage3(errorMessage_3);
            return false;
        } if (email !== "") {
            errorMessage_3 = "";
            setErrorMessage3(errorMessage_3);
        } if (mobile === "") {
            errorMessage_4 = t('signUp.error4');
            setErrorMessage4(errorMessage_4);
            return false;
        } if (mobile !== "") {
            errorMessage_4 = "";
            setErrorMessage4(errorMessage_4);
        } if (mobile.charAt(0) === "0") {
            errorMessage_5 = t('signUp.error5');
            setErrorMessage5(errorMessage_5);
            return false;
        }
        if (mobile.charAt(0) !== "0") {
            errorMessage_5 = "";
            setErrorMessage5(errorMessage_5);
        } if (mobile.length != 9) {
            errorMessage_6 = t('signUp.error6');
            setErrorMessage6(errorMessage_6);
            return false;
        }
        if (mobile.length == 9) {
            errorMessage_6 = "";
            setErrorMessage6(errorMessage_6);
        }

        return true;
    };

    const nextBtnClick = () => {

        setIsLoading(true);
        let phone = Constants.MOBILE_PREFIX + mobile;
        let body = {
            "firstName": name,
            "lastName": lastName,
            "mobile": phone,
            "email": email
        };
        const isValidate = validate();

        if (isValidate) {
            axios({
                method: 'GET',
                url: `${baseUrl}/user/check-mobile-for-add/${phone}`,
                headers: {
                    Authorization: genToken
                }
            })
                .then((response) => {
                    const userData = response.data.data
                    localStorage.setItem("enc_mobile", userData);
                    if (userData !== null) {
                        generateOTP();  
                    } else {
                        setIsLoading(false);
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            text: t('signUp.alert6'),
                            showConfirmButton: true,
                            confirmButtonText: t('signUp.yes'),
                            showCancelButton: true,
                            cancelButtonText: t('signUp.no'),
                            width: '400px',
                        }).then((result) => {
                            if (result.value == true) {
                                history.push('/login')
                            }
                        })
                    }
                }).catch((error) => {
                console.log("viewToken data fetching error : " + error.response);
                Swal.close();
                setIsLoading(false);

                console.log(error.response.data.data);
                if (error.response.data.data !== null) {
                    swtAlert('warning', error.response.data.message);
                } else {
                    const errorMsg = validate(error);
                    swtAlert('error', errorMsg);
                }

            })
        } else {
            setIsLoading(false);
        }
    };

    const generateOTP = () => {
        let phone = Constants.MOBILE_PREFIX + mobile;
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${gateway}/sms/add-otp`,
            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVU0VSIiwiVVVJRCI6IjAxYThhMjllLWQ3YTgtNGUxOC1iYmQ3LTY4OGQyNjYxOGJmOSIsImlzcyI6InRzLWdhdGV3YXkiLCJpYXQiOjE3MTg4NjI1MzQsImV4cCI6MjYzMDcyMDAwMH0.g7qr_oVcEg9rCKi_lQND0ojORe2ZJTcR3D7cTIUhaQQ'
            },
            data: {
                "number": phone
            }
        }).then((response) => {
            if (response.data.data !== null) {
                localStorage.setItem(Constants.MOBILE, mobile);
                localStorage.setItem("otp_auth", response.data.data);
                history.push({
                    pathname: '/register-otp',
                    state: {
                        data: { "name": name, "lastName": lastName, "mobile": mobile, "email": email },
                    },
                })
            } else {
                Swal.fire('', t('login.alert1'), 'error');
                setIsLoading(false)
            }
        }).catch((error) => {
            setIsLoading(false)
            console.log(error);
            Swal.fire('', error.response.data.data, 'error');
        })
    };

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {

            }
        }, authentication);
    };

    const navigateToLogin = () => {
        history.push('/login')
    }

    return (


        <div>

            <div className="body-scroll d-flex flex-column h-100">

                {/* <!-- Begin page content --> */}
                <main className="flex-shrink-1 main d-flex align-content-between flex-wrap">
                    <LoginHeader setLanguage={setLanguage} />


                    <div className="container mt-3 mb-4 text-center col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                        <figure className="avatar avatar-100 mb-4">
                            <div className="background">
                                <img src="img/personal.png" alt="" />
                            </div>
                        </figure>
                        <h3 className="text-white">{t('signUp.description')}</h3>
                        <p className="text-white mb-4">{t('signUp.description2')}<br /> {t('signUp.description3')}</p>
                    </div>
                    <div id='div1' />


                    <div className="main-container">

                        {/* <!-- page content start --> */}
                        {isLoading ? <Loading /> :

                            <div className="container">
                                <div className="card slide-up">
                                    <div className="card-body text-center">

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 align-self-center mb-4">
                                                    <div className="row px-3 justify-content-center row-home">

                                                        <form>

                                                            <div className={"form-group float-label " + (name.length > 0 ? 'active' : '')}>
                                                                <input type="text" className="form-control"
                                                                    onChange={(e) => setName(e.target.value)} />
                                                                <label className="form-control-label">{t('signUp.firstName')}</label>
                                                            </div>
                                                            <p style={{ color: "red" }}>{errorMessage1}</p>

                                                            <div className={"form-group float-label position-relative " + (lastName.length > 0 ? 'active' : '')}>
                                                                <input type="text" className="form-control"
                                                                    onChange={(e) => setLastName(e.target.value)} />
                                                                <label className="form-control-label">{t('signUp.lastName')}</label>
                                                            </div>
                                                            <p style={{ color: "red" }}>{errorMessage2}</p>

                                                            <div className={"form-group float-label position-relative " + (email.length > 0 ? 'active' : '')}>
                                                                <input type="email" className="form-control"
                                                                    onChange={(e) => setEmailAddress(e.target.value)} />
                                                                <label className="form-control-label">{t('signUp.email')}</label>
                                                            </div>
                                                            <p style={{ color: "red" }}>{errorMessage3}</p>

                                                            <div className={"input-group input-group-sm mb-3 form-group float-label position-relative " + (mobile.length > 0 ? 'active' : '')}>
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text">{Constants.MOBILE_PREFIX}</span>
                                                                </div>
                                                                <input type="tel" pattern="[0-9]{9}" required
                                                                    inputMode="tel" maxLength="9"
                                                                    className="form-control pl-1" name="mobile"
                                                                    title="Enter your phone number without 0."
                                                                    onChange={(e) => setMobileNumber(e.target.value)} />
                                                                <label className="form-control-label pl-5">{t('signUp.mobile')}</label>
                                                            </div>
                                                            <p style={{ color: "red" }}>{errorMessage4}</p>
                                                            <p style={{ color: "red" }}>{errorMessage5}</p>
                                                            <p style={{ color: "red" }}>{errorMessage6}</p>
                                                            <p className="text-center text-shadow text-secondary">{t('signUp.footer')}</p>


                                                            <button type="button" className="mb-2 btn btn-primary px-5"
                                                                onClick={nextBtnClick}>{t('signUp.next')}
                                                            </button>

                                                            <p className='text-secondary'>{t('login')}
                                                                <a style={{ marginLeft: '5px' }} onClick={() => navigateToLogin()}>{t('signUp.login')}</a>
                                                            </p>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        }


                    </div>
                </main>

            </div>
            {/*{isLoaded && <Loading />}*/}
            {/* <!-- screen loader --> */}


        </div>
    )

}



