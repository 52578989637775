import axios from 'axios';
import React, {use, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Swal from 'sweetalert2';
import validate from '../config/util';
import {useTranslation} from "react-i18next";
import * as Constants from "../util/Constant";
import GetTokenModalPrefContact from '../components/GetTokenModalPrefContact';
import GetTokenModalGetMobileNo from '../components/GetTokenModalGetMobileNo';


const mqtt = require('mqtt/dist/mqtt');
let client = null;
let CryptoJS = require("crypto-js");

const GetTokenModal = ({showGetTokenModal, setShowGetTokenModal, selectedBranch, selectedService, ticketUrl, mobile}) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [config, setConfig] = useState({});
    const [tokenDetails, setTokenDetails] = useState({});
    const [selectedPreferredContact, setSelectedPreferredContact] = useState(null);
    const [verifiedMobileNo, setVerifiedMobileNo] = useState('');
    const [genToken, setGenToken] = useState(null);

    const {t} = useTranslation();
    const history = useHistory();

    const [seconds, setSeconds] = useState(0);
    const [prefContactMethods, setPrefContactMethods] = useState([]);

    const [gateway, setGateway] = useState('');

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                    setGenToken(decrypt.GEN_TOKEN);
                    setGateway(decrypt.GATEWAY_URL);
                    setConfig(decrypt);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
            setGenToken(decrypt.GEN_TOKEN);
            setGateway(decrypt.GATEWAY_URL);
            setConfig(decrypt);
        }
    }, [GetTokenModal]);

    useEffect(() => {
        if (showGetTokenModal) {
            if (ticketUrl != null && ticketUrl.length > 0) {
                setSeconds(45)
            } else {
                setSeconds(15)
            }

        }
    }, [showGetTokenModal])

    useEffect(() => {
        if (seconds > 0) {
            const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timerId);
        }

        if (seconds === 0) {
            setShowGetTokenModal(false);
        }

    }, [seconds]);

    const formatTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const remainingSeconds = secs % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    // when show modal
    useEffect(() => {
        if (showGetTokenModal === true) {

            if (baseUrl.length > 0)
                viewToken();
        }
    }, [showGetTokenModal, baseUrl]);

    // call - subscribe mqttConnect function
    useEffect(() => {
        if (selectedService.id > 0) {
            mqttConnect();
        }
    }, [selectedService.id]);

    // call - unsubscribe mqttConnect function when close modal
    useEffect(() => {
        if (showGetTokenModal === false) {
            mqttDisconnect();
        }
    }, [showGetTokenModal]);

    // get customer mobile no
    useEffect(() => {
        // check default or QR
        if (mobile !== undefined) {
            setVerifiedMobileNo(mobile)
        } else {
            if (ticketUrl === null | ticketUrl.length === 0) {
                // if default, get from local storage
                let prof = localStorage.getItem(Constants.USER_PROFILE);
                if (prof == null) return;
                let user = JSON.parse(prof);
                setVerifiedMobileNo(user.mobile);

            } else {
                // if qr, set null
                setVerifiedMobileNo(null);
            }
        }

    }, [showGetTokenModal])


    // auto refresh - subscribe
    function mqttConnect() {

        const host = config.BROKER;
        const clientId = `ws_${Math.random().toString(16).slice(3)}_tstpl`;

        let options = {
            // protocol: "wss",
            clean: true,
            connectTimeout: 15000,
            useSSL: true,
            // clientId uniquely identifies client
            // choose any string you wish
            username: config.USERNAME,
            password: config.PASSWORD,
            clientId: clientId,
            reconnectPeriod: 1000,
        };
        try {
            client = mqtt.connect(host, options);
            let topicService = Constants.TOPIC_CATEGORY + `${selectedService.id}`;

            // ws connection
            client.on('connect', () => {
                console.log('Connected !');

                // subscribe to topics
                client.subscribe([topicService], () => {
                    console.log(`Subscribe to topic '${topicService}'`)
                });

            });

            //on message arrived
            client.on('message', (topic, payload) => {
                console.log('Received Message:', topic, payload.toString());
                if (topic === topicService) {
                    viewToken();
                }
            });
        } catch (e) {
            console.error(`Connection to ${host} failed ${e}`);
        }
    }

    // auto refresh - unsubscribe 
    function mqttDisconnect() {
        if (client != null) {
            client.unsubscribe(Constants.TOPIC_CATEGORY + `${selectedService.id}`);
            client.end();
        }
        // console.log('topic unsubscribed');
    }

    // validate pref. contact
    function validatePrefContact() {
        if (prefContactMethods.length === 0) {
            getToken();
        } else {
            if (selectedPreferredContact === null) {
                swtAlert('error', t('home.modal.alert1'));
            } else {
                getToken();
            }
        }
    }

    // viewToken - function
    function viewToken() {
        // loader
        Swal.fire({
            position: 'center',
            text: t('home.modal.loading'),
            allowOutsideClick: false,
            width: "200px"
        });
        Swal.showLoading();

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-live-token-data/category/${selectedService.id}`,
            headers: {
                Authorization: genToken
            }
        }).then((result) => {
            Swal.close();
            setTokenDetails(result.data.data);
        }).catch((error) => {
            console.log("viewToken data fetching error : " + error.response);
            Swal.close();

            if (error.response.data.data === null) {
                swtAlert('warning', error.response.data.message);
            } else {
                const errorMsg = validate(error);
                swtAlert('error', errorMsg);
            }

        })
    }

    // getToken - function
    function getToken() {
        // loader
        Swal.fire({
            position: 'center',
            text: t('home.modal.loading'),
            allowOutsideClick: false,
            width: "200px"
        });
        Swal.showLoading();

        // alert(verifiedMobileNo)

        let body = {
            bid: selectedBranch.id,
            categoryId: selectedService.id,
            vip: false,
            customer: {
                name: null,
                mobile: verifiedMobileNo,
                nic: null,
                email: null
            },
            nestedCategories: null,
            sendSMS: false,
            token: {}
        }

        axios({
            method: 'POST',
            url: `${baseUrl}/ticket/add`,
            headers: {
                Authorization: genToken
            },
            data: body
        }).then((result) => {
            Swal.close();

            if (result.data.message !== "success") {
                swtAlert('warning', result.data.message);
            } else {

                // disconnect mqttConnection
                mqttDisconnect();

                history.push({
                    pathname: `/track/${result.data.data.ticketId}`
                });
            }

        }).catch((error) => {
            console.log("getToken data fetching error : " + error.response);
            Swal.close();

            if (error.response.data.data === null) {
                swtAlert('warning', error.response.data.message);
            } else {
                const errorMsg = validate(error);
                swtAlert('error', errorMsg);
            }
        })
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('home.modal.dismiss'),
            // timer: 2500,
            width: '400px',
        })
    }


    return (
        <>

            {showGetTokenModal ? (

                <div className='getTokenModal-root'>

                    <div className="modal-header">
                        <h6 className="modal-title" id="exampleModalLabel">{t('home.modal.availableNumber')}</h6>
                        <div className="row px-3 justify-content-center">
                            <div className="col-12 align-self-center">
                                <h1 className="text-green fw700">
                                    {tokenDetails.nextTicketNumber}
                                    {/* token number */}
                                </h1>
                            </div>
                            <div className="col-12 align-self-center mb-2">
                                <h6 className="text-uppercase mb-1 fw600">{t('home.modal.estimatedCallTime')}</h6>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 px-3 d-flex align-items-center justify-content-center">
                                <span className="material-icons pr-1">{t('home.modal.schedule')}</span>
                                <h6 className="pt-1">{tokenDetails.est}</h6>
                            </div>
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className="row px-3 pt-2 justify-content-center">
                            {tokenDetails.lastServedTicketStatus !== null ? (
                                <>
                                    {
                                        tokenDetails.lastServedTicketStatus === 1 ? (
                                            <div className="col-6 align-self-center px-0 mb-2">
                                                <p className="font-weight-bold mb-0 pb-0">{t('home.modal.currentServing')}</p>
                                                <h5 className="text-primary fw500">{tokenDetails.lastServedTicketNumber}</h5>
                                            </div>
                                        ) : (
                                            <div className="col-6 align-self-center px-0 mb-2">
                                                <p className="font-weight-bold mb-0 pb-0">{t('home.modal.lastServing')}</p>
                                                <h5 className="text-primary fw500">{tokenDetails.lastServedTicketNumber}</h5>
                                            </div>
                                        )
                                    }
                                </>
                            ) : (
                                <div className="col-6 align-self-center px-0 mb-2">
                                    <p className="font-weight-bold mb-0 pb-0">{t('home.modal.notAvailable')}</p>
                                </div>
                            )}
                            <div className="col-6 align-self-center mb-2">
                                <p className="font-weight-bold mb-0 pb-0">{t('home.modal.waiting')}</p>
                                <h5 className="text-primary fw500">{tokenDetails.waitingTicketCount}</h5>
                            </div>
                        </div>

                        {ticketUrl != null && ticketUrl.length > 0 ? (
                            <GetTokenModalGetMobileNo
                                setShowGetTokenModal={setShowGetTokenModal}
                                getToken={getToken}
                                setVerifiedMobileNo={setVerifiedMobileNo}
                                gateway={gateway}
                                selectedBranch={selectedBranch}
                                selectedService={selectedService.id}
                            />
                        ) : (
                            <GetTokenModalPrefContact
                                selectedPreferredContact={selectedPreferredContact}
                                setSelectedPreferredContact={setSelectedPreferredContact}
                                setShowGetTokenModal={setShowGetTokenModal}
                                validatePrefContact={validatePrefContact}
                                selectedBranch={selectedBranch}
                                prefContactMethods={prefContactMethods}
                                setPrefContactMethods={setPrefContactMethods}
                            />
                        )}
                    </div>
                    <div>This window will close after <span className="text-danger">{formatTime(seconds)}</span> seconds
                    </div>
                </div>

            ) : null}
        </>
    )
};
export default GetTokenModal
