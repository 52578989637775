import React, {useEffect, useState} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.css';

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import LinkExpired from "./pages/LinkExpired";
import TokenHistory from "./pages/TokenHistory";
import GetToken from "./pages/GetToken";
import LoginOTP from './pages/LoginOTP';
import TokenLiveView from "./pages/TokenLiveView";
import QRGenerate from './pages/QRGenerate';
import ConfigScreen from './pages/ConfigScreen';
import EditProfile from './pages/EditProfile';
import * as Constants from "./util/Constant";
import NotFound from './pages/NotFound';
import Otp from "./pages/Otp";


let CryptoJS = require("crypto-js");
const App = () => {
    const [login, setLogin] = useState(null);

    //get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText != null) {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setLogin(decrypt.LOGIN);
        }
        fetch(Constants.CONFIG_PATH).then(response => {
            response.json().then(settings => {
                console.info("TSTPL: Welcome !");
                // save configuration data on localStorage
                localStorage.setItem(Constants.CONFIG, settings.cipher);
                let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                setLogin(decrypt.LOGIN);
            }).catch(error => {
                console.error(error);
                console.log("Couldn't find the configurations file");
            })
        });
    }, [App]);


    return (

        <div>
            <HashRouter>
                <Switch>
                    <Route path="/track/:id" component={TokenLiveView}/>
                    <Route path="/get-token" component={GetToken}/>
                    <Route path="/link-expired" component={LinkExpired}/>
                    <Route path="/token-history" component={TokenHistory}/>
                    <Route path="/config-screen" component={ConfigScreen}/>
                    <Route exact path="/" component={Home}/>

                    <Route path="/qr-generate" component={QRGenerate}/>
                    <Route path="/qr" component={GetToken}/>

                    <Route path="/login-otp" component={login ? LoginOTP : NotFound}/>
                    <Route path="/login" component={login ? Login : NotFound}/>
                    <Route path="/register-otp" component={login ? Otp : NotFound}/>
                    <Route path="/register" component={login ? Register : NotFound}/>
                    <Route path="/edit-profile" component={login ? EditProfile : NotFound}/>
                    <Route component={NotFound}/>

                </Switch>
            </HashRouter>
        </div>
    );
}

export default App;
