import React, {useState} from 'react';
import * as Constants from "../util/Constant";
import Swal from 'sweetalert2';

let CryptoJS = require("crypto-js");

const ConfigScreen = () => {

    const [apiUrl, setApiUrl] = useState('http://103.1.179.245:8080/ts-queue-edge');
    const [gateway, setGateway] = useState('http://103.1.179.245:8080/ts-gateway');
    const [qrBaseUrl, setQrBaseUrl] = useState('http://localhost:3000/mwa/#/qr?c=');
    const [broker, setBroker] = useState('ws://103.1.179.245:9005');
    const [mqUsername, setMqUsername] = useState('tsmq');
    const [mqPassword, setMqPassword] = useState('Ts@1234');
    const [branchSearchBar, setBranchSearchBar] = useState('true');
    const [serviceSearchBar, setServiceSearchBar] = useState('true');
    const [login, setLogin] = useState('true');
    const [password, setPassword] = useState('Ts@1234');
    const [genToken, setGenToken] = useState('Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJFREdFX0dFTiIsIm5hbWUiOiJUU1RQTCIsImlhdCI6MTYzODA4MjQ4MCwiYXV0aG9yIjoidmloYW5nYXdpY2tzIiwiZXhwIjoyMjAyODk2OTAwMH0.sIPiV_IPf1LqFeRWiVmoxZyc64n61kUfnkpBSrQ1Cd4');

    // encrypt configurations - function
    function encryptFunction() {
        //Ts@0987654321

        if (password === 'Ts@0987654321') {

            let cipher = CryptoJS.AES.encrypt(JSON.stringify("{\n" +
                "  \"API_URL\": \"" + `${apiUrl}` + "\",\n" +
                "  \"GATEWAY_URL\": \"" + `${gateway}` + "\",\n" +
                "  \"QR_BASE_URL\": \"" + `${qrBaseUrl}` + "\",\n" +
                "  \"BROKER\": \"" + `${broker}` + "\",\n" +
                "  \"USERNAME\": \"" + `${mqUsername}` + "\",\n" +
                "  \"PASSWORD\": \"" + `${mqPassword}` + "\",\n" +
                "  \"BRANCH_SEARCH_BAR\": " + `${branchSearchBar}` + ",\n" +
                "  \"SERVICE_SEARCH_BAR\": " + `${serviceSearchBar}` + ",\n" +
                "  \"GEN_TOKEN\": \"" + `${genToken}` + "\",\n" +
                "  \"LOGIN\": " + `${login}` + "\n" +
                "}"), Constants.ENCRYPT_KEY).toString();

            Swal.fire({
                text: cipher,
            });
            // console.log(cipher);

        } else {
            Swal.fire({
                icon: 'error',
                text: 'Wrong Password',
            })
        }


    }

    return (
        <main className="flex-shrink-1 main d-flex align-content-between flex-wrap">

            <header className="header">
                <div className="row">
                    <div className="col-auto ml-3 px-0">
                        <button className="btn-circle btn-link back-btn" type="button">
                            <span className="material-icons">arrow_back</span>
                        </button>
                    </div>
                    <div className="text-left col-6 align-self-center">
                    </div>

                </div>
            </header>


            <div className="container mt-3 mb-4 text-center col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                <figure className="avatar avatar-100 mb-4">
                    <div className="background">
                        <img src="img/personal.png" alt=""/>
                    </div>
                </figure>
                <h3 className="text-white">Configuration Screen</h3>
                <p className="text-white mb-4">Here is the configurations</p>
            </div>

            <div className="main-container">
                <div className="container">
                    <div className="card slide-up">
                        <div className="card-body text-center">

                            <div className="container">
                                <div className="row">
                                    <div className="col-12 align-self-center mb-4">
                                        <div className="row px-3 justify-content-center row-home">
                                            <form className='config-screen-form'>
                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setApiUrl(e.target.value)
                                                           }} value={apiUrl}/>
                                                    <label className="form-control-label">API URL</label>
                                                </div>
                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setGateway(e.target.value)
                                                           }} value={gateway}/>
                                                    <label className="form-control-label">GATEWAY URL</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setQrBaseUrl(e.target.value)
                                                           }} value={qrBaseUrl}/>
                                                    <label className="form-control-label">QR BASE URL</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setBroker(e.target.value)
                                                           }} value={broker}/>
                                                    <label className="form-control-label">BROKER</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setMqUsername(e.target.value)
                                                           }} value={mqUsername}/>
                                                    <label className="form-control-label">MQ USERNAME</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="password" className="form-control"
                                                           onChange={(e) => {
                                                               setMqPassword(e.target.value)
                                                           }} value={mqPassword}/>
                                                    <label className="form-control-label">MQ PASSWORD</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setBranchSearchBar(e.target.value)
                                                           }} value={branchSearchBar}/>
                                                    <label className="form-control-label">BRANCH SEARCH BAR</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setServiceSearchBar(e.target.value)
                                                           }} value={serviceSearchBar}/>
                                                    <label className="form-control-label">SERVICE SEARCH BAR</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setLogin(e.target.value)
                                                           }} value={login}/>
                                                    <label className="form-control-label">SHOW LOGIN</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="password" className="form-control"
                                                           onChange={(e) => {
                                                               setPassword(e.target.value)
                                                           }}/>
                                                    <label className="form-control-label">Password</label>
                                                </div>

                                                <div className="form-group float-label active">
                                                    <input type="text" className="form-control"
                                                           onChange={(e) => {
                                                               setGenToken("Bearer " + e.target.value)
                                                           }} value={genToken}/>
                                                    <label className="form-control-label">General Token (EDGE-GEN
                                                        token)</label>
                                                </div>

                                                <button type="button" className="mb-2 btn btn-primary px-5"
                                                        onClick={encryptFunction}
                                                >Get Encrypt Code
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </main>
    )
}

export default ConfigScreen