import axios from 'axios';
import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import validate from '../config/util';
import {useTranslation} from "react-i18next";
import * as Constants from "../util/Constant";
import MyTokenTop from "./MyTokenTop";
import {useHistory} from 'react-router-dom';

let CryptoJS = require("crypto-js");
const NoShowTicket = ({liveTicketDetails, getLiveTicketDetails, tokenResponse, wfs, setWorkFlows}) => {

    const [baseUrl, setBaseUrl] = useState('');
    const { t } = useTranslation();

    const history = useHistory();

    const [markedArrival, setMarkedArrival] = useState(false);
    const [genToken, setGenToken] = useState(null);

    // get base url
    useEffect(() => {

        let cipherText = localStorage.getItem(Constants.CONFIG);
        let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
        let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        setBaseUrl(decrypt.API_URL);
        setGenToken(decrypt.GEN_TOKEN);

    }, [NoShowTicket]);

    // markArrival - function
    function markArrival() {
        axios({
            method: 'GET',
            url: `${baseUrl}/ticket/mark-as-arrived/ticket/${liveTicketDetails.token}`,
            headers: {
                Authorization: genToken
            }
        }).then((result) => {

            if (result.data.data.data === true) {
                setMarkedArrival(true);
            }

        }).catch((error) => {
            console.log("markArrival error : " + error.response);
            const errorMsg = validate(error);
            Swal.fire('', errorMsg, 'error');
        })

    }

    const cancelAppointment = (ticketId) => {
        Swal.fire({
            position: 'center',
            text: t('appointment.alert5'),
            allowOutsideClick: false,
            width: "200px"
        });
        Swal.showLoading();

        axios({
            method: 'POST',
            url: `${baseUrl}/ticket/${ticketId}/cancel`,
            headers: {
                Authorization: genToken
            }
        }).then((result) => {
            Swal.close();

            swtAlert('success', null);

            history.push('/get-token')

        }).catch((error) => {
            Swal.close();
            console.log(error);

            swtAlert('error', t('appointment.alert3'));
        })
    }

    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('pending.alert2'),
            width: '400px',
        })
    }

    const backToTicket = () => {
        // getLiveTicketDetails(true, false)
    }

    return (

        <>
            <div className="main-container position-fixed vh-90 no-scroll">
                <div className="container pt-4 text-center">

                    <h6 className="mb-3 fw600">{t('token.header')}</h6>

                    <div className="row mx-3">
                        <div className="col-12 px-0">
                            <div className="card my-token-top-area slide-up">

                                <MyTokenTop liveTicketDetails={liveTicketDetails} />
                                <div
                                    className="card-body bg-white border-0 rounded-top rounded-bottom vh-50 text-center pb-5">
                                    <div className="container">
                                        <div className="row">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className={wfs !== null ? 'container' : 'no-scroll container'}>
                <div className="col-12" style={{ top: "12em", zIndex: 999 }}>
                    <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                        <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                            <div className="card-body">
                                <div className="container">
                                    <div className="row mb-3">
                                        <div className="col-6 align-self-center">
                                            <h6 className="text-secondary text-lable fw600 mb-0">{t('noShowTicket.department')}</h6>
                                            <h6>{liveTicketDetails.categoryName}</h6>
                                        </div>
                                        <div className="col-6 d-flex align-items-center justify-content-left">

                                            <div className="avatar avatar-40 rounded-circle">
                                                <figure className="m-0 background"
                                                    style={{ backgroundImage: ("img/no-show-icon.png") }}>
                                                    <img src="img/no-show-icon.png" alt=""
                                                        style={{ width: "100%" }} />
                                                </figure>
                                            </div>

                                            <h5 className="text-dark text-uppercase m-0 fw700 pl-2">{t('noShowTicket.noShow')}</h5>

                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6 align-self-center">
                                            <h6 className="text-secondary text-lable fw600 mb-0">{t('noShowTicket.token')}</h6>
                                        </div>
                                        <div className="col-6 align-self-center">
                                            <h6 className="text-secondary text-lable fw600 mb-0">{t('noShowTicket.currentServing')}</h6>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-6 d-flex align-items-baseline justify-content-left">
                                            <h1 className="text-danger fw700 pr-1">{liveTicketDetails.tokenNumber}</h1>
                                            <h6 className="text-danger text-uppercase fw600">{t('noShowTicket.noshow2')}</h6>
                                        </div>
                                        <div className="col-6 align-self-center">
                                            <h1 className="text-primary fw700">{liveTicketDetails.lastToken}</h1>
                                        </div>
                                    </div>
                                    <hr className="mt-0" />
                                    <div className="row">
                                        <div className="col-12 text-center align-self-center">
                                            <p className="text-dark fw600">{t('noShowTicket.list')}</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 text-center align-self-center">

                                            {markedArrival ? (
                                                <>
                                                    <div style={{
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        margin: '20px 0',
                                                        color: "#343a40"
                                                    }}>Your arrival has been marked
                                                    </div>
                                                    <button type="button" className="my-4 btn btn-danger"
                                                            style={{bottom: '-5px'}}
                                                            onClick={() => backToTicket()}
                                                    >Back to My Ticket
                                                    </button>
                                                </>
                                            ) : (
                                                <button type="button" className="my-4 btn btn-danger"
                                                        style={{bottom: '-5px'}}
                                                        onClick={() => markArrival()}
                                                >{t('noShowTicket.arrived')}
                                                </button>
                                            )}

                                            <button type="button" className="ml-1 my-4 btn btn-danger"
                                                    style={{bottom: '-5px'}}
                                                    onClick={() => cancelAppointment(liveTicketDetails.token)}
                                            >Cancel Appointment
                                            </button>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <Workflow tokenResponse={tokenResponse} wfs={wfs} setWorkFlows={setWorkFlows} /> */}

                </div>
            </div>


        </>

    )
};

export default NoShowTicket;