import React, {useEffect, useState} from 'react'
import CustomerFeedBackModal from '../modal/CustomerFeedBackModal';
import {useTranslation} from "react-i18next";
import MyTokenTop from "./MyTokenTop";

const ServingTicket = ({ liveTicketDetails, tokenResponse, bookId, fbGiven, setFbGiven, wfs, setWorkFlows }) => {

    const [showCfbModal, setShowCfbModal] = useState(false);

    const { t } = useTranslation();

    // open feedback modal when complete token
    useEffect(() => {
        if (Object.keys(liveTicketDetails).length > 0) {
            if (liveTicketDetails.tokenStatus === 4) {
                displayCfbModal();
            }
        }
    }, [liveTicketDetails.tokenStatus]);


    function displayCfbModal() {
        setShowCfbModal(prev => !prev);
    }


    return (
        <>
            {/* <!-- page content start --> */}


            {showCfbModal ?
                <div className="main-container position-fixed vh-90 no-scroll">

                </div>

                :

                <>
                    <div className="main-container position-fixed vh-90 no-scroll">
                        <div className="container pt-4 text-center">

                            <h6 className="mb-3 fw600">{t('token.header')}</h6>

                            <div className="row mx-3">
                                <div className="col-12 px-0">
                                    <div className="card my-token-top-area slide-up">

                                        <MyTokenTop liveTicketDetails={liveTicketDetails} />

                                        <div
                                            className="card-body serving-token-background-color border-0 rounded-top rounded-bottom vh-50 text-center pb-5">
                                            <div className="container">
                                                <div className="row">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={wfs !== null ? 'container' : 'no-scroll container'}>
                        <div className="col-12" style={{ top: "12em", zIndex: 999 }}>
                            <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 align-self-center my-4">
                                                <div className="btn bell-container py-2">
                                                    <span className="material-icons mr-2">notifications_active</span>
                                                    {t('serving.calling')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 align-self-center text-white">
                                                <h6 className="fw600 mb-0">{t('serving.department')}</h6>
                                                <h5 className="fw600">{liveTicketDetails.categoryName}</h5>
                                                <h1 className="fw700">{liveTicketDetails.tokenNumber}</h1>
                                                <h6 className="fw500">{t('serving.proceed')}<span
                                                    className="fw700">{t('serving.counter')} {liveTicketDetails.counter}</span>
                                                </h6>
                                                {/*<h5 className="fw600">{ticketOtherDetails.avgServingTime}</h5>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <Workflow tokenResponse={tokenResponse} wfs={wfs} setWorkFlows={setWorkFlows} /> */}
                        </div>
                    </div>
                </>

            }

            <div className={showCfbModal ? 'modal-outside' : ''}></div>

            <CustomerFeedBackModal showCfbModal={showCfbModal}
                fbGiven={fbGiven}
                bookId={bookId}
                setFbGiven={setFbGiven}
                setShowCfbModal={setShowCfbModal}
                liveTicketDetails={liveTicketDetails}
            />

        </>

    )
};

export default ServingTicket;