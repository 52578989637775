import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import * as Constants from "../util/Constant";
import LoginHeader from "../components/LoginHeader";
import {useTranslation} from "react-i18next";
import {authentication} from "../config/firebase";
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import Loading from "../components/Loading";
import OtpInput from 'react-otp-input';

// let user = null;
let CryptoJS = require("crypto-js");

const Otp = (props) => {

    let lang = localStorage.getItem(Constants.LANG);

    const location = useLocation();
    const history = useHistory();
    const ref = useRef(null);
    const { t } = useTranslation();

    const [otp, setOtp] = useState('');
    const [baseUrl, setBaseUrl] = useState('');
    const [isLoading2, setIsLoading2] = useState(false);
    const [gateway, setGateway] = useState('');

    const [minutes, setMinutes] = useState(Constants.OTP_TRY_AGAIN_MINUTES);
    const [seconds, setSeconds] = useState(Constants.OTP_TRY_AGAIN_SECONDS);

    const [showOtpInput, setShowOtpInput] = useState(false);

    const [genToken, setGenToken] = useState(null);

    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                    setGateway(decrypt.GATEWAY_URL);
                    setGenToken(decrypt.GEN_TOKEN);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
            setGenToken(decrypt.GEN_TOKEN);
            setGateway(decrypt.GATEWAY_URL);
        }
    }, [Otp]);

    // focus input field
    useEffect(() => {
        setTimeout(() => {
            setShowOtpInput(true);
        }, 300);
    }, [baseUrl]);

    // countdown
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    // setIsCountDown(false);
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        }
    });

    // when enter otp, call verifyOTP function
    useEffect(() => {
        if (otp.length === 6) {
            verifyOTP();
        }
    }, [otp])


    const verifyOTP = () => {
        if (otp.length === 6) {

            // loader
            Swal.fire({
                position: 'center',
                text: t('home.modal.loading'),
                allowOutsideClick: false,
                width: "200px"
            });
            Swal.showLoading();

            axios({
                method: 'POST',
                url: `${gateway}/sms/authenticate-otp`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("otp_auth")}`
                },
                data: {
                    otp: otp
                }
            }).then((response) => {

                Swal.hideLoading();
                Swal.close()
                addUser()
            })
                .catch((error) => {
                    Swal.hideLoading();
                    setIsLoading2(false);
                    if (error.response !== undefined && error.response !== null && error.response.data !== undefined && error.response.data !== null)
                        if (error.response.data.data !== undefined) {
                            Swal.fire('', error.response.data.data, 'error')
                        } else {
                            Swal.fire('', error.response.data.error, 'error')
                        }
                    else Swal.fire('', 'Something went wrong!', 'error')
                });
        } else {
            return Swal.fire('', t('otp.alert1'), 'error');
        }
    }

    const addUser = () => {
        let moblieNumber = Constants.MOBILE_PREFIX + props.history.location.state.data.mobile;
        let body = {
            "firstName": props.history.location.state.data.name,
            "lastName": props.history.location.state.data.lastName,
            "mobile": moblieNumber,
            "email": props.history.location.state.data.email
        };
        setIsLoading2(true);
        axios.post(`${baseUrl}/user/register`, body,
            {headers: {Authorization: genToken}}).then((response) => {
                if (response.data.message === 'success')
                    userAccountAccess();
                else {
                    Swal.fire('', response.data.message, 'error');
                }
            }
        ).catch((error) => {
            console.log(error);
            if (error.response.status === 400) {
                Swal.fire('', t('otp.alert6'), 'error');

            } else if (error.response.status === 401) {
                Swal.fire('', t('otp.alert8'), 'error');

            } else if (error.response.status === 403) {
                Swal.fire('', t('otp.alert10'), 'error');

            } else {
                Swal.fire('', t('otp.alert12'), 'error');
            }
        })
    }

    const userAccountAccess = () => {

        setIsLoading2(true)
        let mobile = Constants.MOBILE_PREFIX + props.history.location.state.data.mobile;

        axios({
            method: 'POST',
            url: `${baseUrl}/user/login/mobile/${mobile}`,
            headers: {
                Authorization: genToken
            }
        }).then((response) => {
            if (response.data.message === 'success') {
                localStorage.setItem(Constants.TOKEN, (response.data.data.token));
                setIsLoading2(true)
                // history.push('/get-token')
                history.push({
                    pathname: '/get-token',
                    state: {prevUrl: location.pathname}
                });
            } else {
                Swal.fire('', t('loginOtp.alert1'), 'error');
            }
        }).catch((error) => {
            setIsLoading2(false)
            if (error.response.status === 400) {

                Swal.fire('', t('otp.alert6'), 'error');

            } else if (error.response.status === 401) {

                Swal.fire('', t('otp.alert8'), 'error');

            } else if (error.response.status === 403) {

                Swal.fire('', t('otp.alert10'), 'error');

            }
        });
    }

    const resendOtp = (e) => {

        // disable try again button
        setMinutes(Constants.OTP_TRY_AGAIN_MINUTES);
        setSeconds(Constants.OTP_TRY_AGAIN_SECONDS);

        // ref.current && ref.current.focus();

        e.preventDefault()
        let phone = Constants.MOBILE_PREFIX + props.history.location.state.data.mobile;
        if (window.recaptchaVerifier !== undefined) {

            window.recaptchaVerifier.clear();

            if (document.getElementById("recaptcha-container") !== null)
                document.getElementById("recaptcha-container").remove();

        }
        let div = document.createElement("div");
        div.id = "recaptcha-container";

        let element = document.getElementById("div1");
        element.appendChild(div);


        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(authentication, phone, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;

            })
            .catch((error) => {
                console.log(error);
            });

    }

    const generateRecaptcha = () => {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                    },

                },
                authentication
            );

        }

    };

    return (
        <div>
            <main className="flex-shrink-1 main d-flex align-content-between flex-wrap">
                <LoginHeader />
                <div className="container mt-3 mb-4 text-center col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                    <figure className="mb-4">
                        <img src={"img/otp.png"} width="20%" alt="" />
                    </figure>
                    <h3 className="text-white">{t('otp.header')}</h3>
                    <p className="text-white mb-4">{t('otp.description')}</p>
                </div>
                <div id="div1" />

                {isLoading2 ? <Loading /> :
                    <div className="main-container">
                        <div className="container">
                            <div className="card card-home ">
                                <div className="card-body text-center">
                                    <div className="container slide-up">
                                        <div className="row">
                                            <div className="col-12 align-self-center mb-4">
                                                <div className="row px-3 justify-content-center">
                                                    <form>
                                                        <div className="input-group input-group-otp mb-3 pt-4 form-group float-label position-relative"
                                                            data-group-name="digits">

                                                            {showOtpInput ? (
                                                                <OtpInput
                                                                    numInputs={6}
                                                                    value={otp}
                                                                    onChange={setOtp}
                                                                    shouldAutoFocus
                                                                    isInputNum
                                                                    focusStyle={{
                                                                        outline: 'none',
                                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                                        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
                                                                    }}
                                                                    inputStyle={{
                                                                        width: "38px",
                                                                        height: "38px",
                                                                        margin: "0 10px",
                                                                        fontSize: "28px",
                                                                        border: 0,
                                                                        color: '#495057',
                                                                        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                                                                    }}
                                                                />
                                                            ) : null}

                                                        </div>
                                                        <p>{t('loginOtp.footer')} <br />

                                                            <a className={minutes === 0 && seconds === 0 ? '' : 'disable-btn'} onClick={(e) => resendOtp(e)}>
                                                                {t('loginOtp.tryAgain')}
                                                            </a>

                                                            {minutes === 0 && seconds === 0
                                                                ? null
                                                                : <a className={minutes === 0 && seconds === 0 ? '' : 'disable-btn'}> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</a>
                                                            }

                                                        </p>

                                                        <button type="button" className="mb-2 btn btn-primary px-5"
                                                            onClick={verifyOTP}>{t('otp.next')}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </main>


        </div>
    )

}
export default Otp