import React from 'react';
import {useHistory} from 'react-router-dom';
import Footer from '../components/Footer';
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import * as Constants from "../util/Constant";

let CryptoJS = require("crypto-js");

const LinkExpired = () => {

    let prof = localStorage.getItem(Constants.USER_PROFILE);

    const {t} = useTranslation();
    const history = useHistory();

    function redirectTo () {
        if (prof === null) {
            history.push({
                pathname: '/'
            });
        } else {
            history.push({
                pathname: '/get-token'
            });
        }
    }

    return (
        <div>
            {/* Begin page content */}
            <main className='flex-shrink-1 main d-flex align-content-between flex-wrap'>

                {/* Header */}
                <Header/>

                <div className="main-container vh-90 no-scroll">
                    {/* <!-- page content start --> */}
                    <div className="container text-center">
                        <div className="card slide-up">
                            <div className="card-body">
                                <div className="container">
                                    <div className="row vh-90 pb-5 justify-content-center">
                                        <div className="align-self-center w-75">
                                            <div className="row justify-content-center">
                                                <figure className="avatar avatar-150 m-1">
                                                    <div className="background">
                                                        <img src={"img/link-expired-icon.png"} alt=""
                                                             style={{width: '100%'}}/>
                                                    </div>
                                                </figure>
                                            </div>
                                            <h5>{t('linkExpired')}</h5>
                                            <p className="text-secondary text-wrap">{t('linkExpired.description')}</p>

                                            {/*//todo X  */}
                                            <button onClick={() => redirectTo()}
                                                    className="btn btn-light px-5">{t('linkExpired.back')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            <Footer/>
        </div>
    )
}

export default LinkExpired