import React from 'react'

function BaseLoading() {
    return (
        <div className="container-fluid h-100 loader-display">
            <div className="row h-100">
                <div className="align-self-center col">
                    <div className="logo-loading">
                        <div className=" mb-4 rounded-circle">
                            {/*<img src={title.logo} alt="" className="w-100"/>*/}
                        </div>
                        {/*<h4 className="text-primary py-0 my-0">{title.title1}</h4>*/}
                        {/*<h5 className="text-primary">Appointment Booking</h5>*/}
                        <div className="loader-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaseLoading;