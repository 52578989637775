import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import {authentication} from '../config/firebase';
import {RecaptchaVerifier} from 'firebase/auth'
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import * as Constants from '../util/Constant';
import LoginHeader from "../components/LoginHeader";
import Loading from "../components/Loading";


let CryptoJS = require("crypto-js");
const Login = () => {

    const history = useHistory();
    const ref = useRef(null)
    const location = useLocation();

    const [baseUrl, setBaseUrl] = useState('');
    const [gateway, setGateway] = useState('');

    const [mobile, setMobile] = useState('');
    const [errorMessage, setErrorMessage] = useState("");

    const { t } = useTranslation();
    const [isLoading2, setIsLoading2] = useState(false);
    const [genToken, setGenToken] = useState(null);

    // get base url
    useEffect(() => {
        let prof = localStorage.getItem(Constants.USER_PROFILE);
        if (prof !== null) {
            history.push({
                pathname: '/get-token'
            });
            return;
        }
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                    setGenToken(decrypt.GEN_TOKEN);
                    setGateway(decrypt.GATEWAY_URL);
                }).catch(error => {
                    console.log("Couldn't find the configurations file");
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
            setGenToken(decrypt.GEN_TOKEN);
            setGateway(decrypt.GATEWAY_URL);
        }
    }, [Login]);

    useEffect(() => {
        setIsLoading2(false)
    }, []);

    // focus input field
    useEffect(() => {

        setTimeout(() => {
            ref.current && ref.current.focus();
        }, 1000);

    }, [baseUrl]);


    // validate mobile no - function
    const validate = () => {

        if (mobile === "") {
            setErrorMessage(t('login.error1'));
        } else if (mobile.length != 9) {
            setErrorMessage(t('login.error2'));
        } else if (mobile.charAt(0) === "0") {
            setErrorMessage(t('login.error3'));
        } else {
            setErrorMessage("");
            return true;
        }

    };

    // login - function
    const login = () => {
        console.log("login");
        let phone = Constants.MOBILE_PREFIX + mobile;
        setIsLoading2(false);
        const isValidate = validate();
        if (isValidate) {
            // alert(1)
            axios({
                method: 'GET',
                url: `${baseUrl}/user/check-mobile/${phone}`,
                headers: {
                    Authorization: genToken
                }
            }).then((response) => {
                    if (response.data.data !== null) {
                        localStorage.setItem("enc_mobile", response.data.data);
                        generateOTP();
                    } else {
                        Swal.fire('', t('login.alert1'), 'error');
                    }
                }).catch((error) => {
                console.log(error.response);
                Swal.fire('', error.response.data.data, 'error');
                    setIsLoading2(false)
                })
        }
    };

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {

            }
        }, authentication);
    };

    const generateOTP = () => {
        let phone = Constants.MOBILE_PREFIX + mobile;
        setIsLoading2(true);

        axios({
            method: 'POST',
            url: `${gateway}/sms/add-otp`,
            headers: {
                Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVU0VSIiwiVVVJRCI6IjAxYThhMjllLWQ3YTgtNGUxOC1iYmQ3LTY4OGQyNjYxOGJmOSIsImlzcyI6InRzLWdhdGV3YXkiLCJpYXQiOjE3MTg4NjI1MzQsImV4cCI6MjYzMDcyMDAwMH0.g7qr_oVcEg9rCKi_lQND0ojORe2ZJTcR3D7cTIUhaQQ'
            },
            data: {
                "number": phone
            }
        }).then((response) => {
            if (response.data.data !== null) {
                localStorage.setItem(Constants.MOBILE, mobile);
                localStorage.setItem("otp_auth", response.data.data);
                history.push({
                    pathname: '/login-otp',
                    state: {qrstatus: false}
                })
            } else {
                Swal.fire('', t('login.alert1'), 'error');
                setIsLoading2(false)
            }
        }).catch((error) => {
            setIsLoading2(false)
            console.log(error);
            Swal.fire('', error.response.data.data, 'error');
        })
    };

    const navigateToRegister = () => {
        history.push('/register')
    }

    // whenClickEnter - function
    const whenClickEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            login();
        }
    }

    return (
        <div>

            <div className="d-flex flex-column h-100">


                <main className="flex-shrink-1 main d-flex align-content-between flex-wrap">
                    <LoginHeader />

                    <div className="container mt-3 mb-4 text-center col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                        <figure className="avatar avatar-100 mb-4">
                            <div className="background">
                                <img src="img/mobile-verification.png" alt="" />
                            </div>
                        </figure>
                        <h3 className="text-white">{t('login')}</h3>
                        <p className="text-white mb-4">{t('login.description')}<br /> {t('login.description2')}

                        </p>
                    </div>

                    <div id="div1" />
                    <div className="main-container">

                        {isLoading2 ? <Loading /> :

                            <div className="container">
                                <div className="card card-home slide-up">
                                    <div className="card-body text-center vh-30">

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 align-self-center mb-4">
                                                    <div className="row px-3 justify-content-center">
                                                        <form>
                                                            <div className="input-group input-group-sm mb-3 form-group float-label active">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">{Constants.MOBILE_PREFIX}</span>
                                                                </div>
                                                                <input
                                                                    placeholder={'7xxxxxxxx'}
                                                                    type="tel" pattern="[0-9]*" required
                                                                    maxLength="9"
                                                                    inputMode="tel"
                                                                    className="form-control pl-1"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    ref={ref}
                                                                    onChange={(e) => setMobile(e.target.value)}
                                                                    onKeyDown={(e) => whenClickEnter(e)}
                                                                />

                                                                <label className="form-control-label pl-5">{t('login.mobile')}</label>
                                                            </div>
                                                            <p style={{ color: "red" }}>{errorMessage}</p>


                                                            <button type="button" onClick={login} className="mb-2 btn btn-primary px-5">{t('login.next')}
                                                            </button>

                                                            <p style={{ color: '#484849', textAlign: 'center' }}>{t('login.footer')}
                                                                <a style={{ marginLeft: '5px' }} onClick={() => navigateToRegister()}>{t('login.register')}</a>
                                                            </p>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </main>
            </div>


        </div>
    )
}

export default Login