import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCrZH4qiI8aiE88G9FWMFcgMfK5JUhlOIU",
    authDomain: "appointment-booking-dd7b3.firebaseapp.com",
    projectId: "appointment-booking-dd7b3",
    storageBucket: "appointment-booking-dd7b3.appspot.com",
    messagingSenderId: "530642130093",
    appId: "1:530642130093:android:ace93577fc9c17bc"
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);