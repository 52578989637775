const validate = function (error) {
    if (error.response.status === 400) {
        return 'Bad request!';
    } else if (error.response.status === 401) {
        return 'Unauthorized!';
    } else if (error.response.status === 403) {
        return 'You are not allowed to do this!';
    } else {
        return 'Something went wrong!';
    }
    
}

export default validate;