import React from 'react';
import {useTranslation} from "react-i18next";
import MyTokenTop from "./MyTokenTop";


const WaitTicket = ({liveTicketDetails, ticketOtherDetails, tokenResponse, wfs, setWorkFlows}) => {

    const {t} = useTranslation();

    return (

        <>
            <div className="main-container position-fixed vh-90 no-scroll">
                <div className="container pt-4 text-center">

                    <h6 className="mb-3 fw600">{t('token.header')}</h6>

                    <div className="row mx-3">
                        <div className="col-12 px-0">
                            <div className="card my-token-top-area slide-up">

                                <MyTokenTop liveTicketDetails={liveTicketDetails}/>
                                <div
                                    className="card-body bg-white border-0 rounded-top rounded-bottom vh-50 text-center pb-5">
                                    <div className="container">
                                        <div className="row">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={wfs !== null ? 'container' : 'no-scroll container'}>
                <div className="col-12" style={{top: "12em", zIndex: 999}}>
                    <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                        <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('waiting.department')}</h6>
                                        <h6>{liveTicketDetails.categoryName}</h6>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-left">

                                        <div className="avatar avatar-40 rounded-circle">
                                            <figure className="m-0 background"
                                                    style={{backgroundImage: ("img/no-show-icon.png")}}>
                                                <span className="material-icons waiting-color">schedule</span>
                                            </figure>
                                        </div>

                                        <h5 className="text-dark text-uppercase m-0 fw700 pl-2 waiting-color">{t('waiting.waiting')}</h5>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('waiting.token')}</h6>
                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('waiting.servingNo')}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 d-flex align-items-baseline justify-content-left">
                                        <h1 className="text-danger fw700 pr-1 waiting-color">{liveTicketDetails.tokenNumber}</h1>
                                        <h6 className="text-danger text-uppercase fw600 waiting-color">{t('waiting.waiting2')}</h6>
                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h1 className="text-primary fw700">{liveTicketDetails.lastToken}</h1>
                                    </div>
                                </div>
                                <hr className="mt-0"/>
                                <div className="row">
                                    <div className="col-12 text-center align-self-center">
                                        <p className="text-dark fw600 mb-2">{t('waiting.list')}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <Workflow tokenResponse={tokenResponse} wfs={wfs} setWorkFlows={setWorkFlows}/> */}

                </div>
            </div>
        </>
    )
};

export default WaitTicket;