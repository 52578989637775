import React, {useEffect, useState} from 'react'
import Footer from '../components/Footer';
import axios from "axios";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import * as Constants from "../util/Constant";
import {useHistory} from 'react-router-dom';

let CryptoJS = require("crypto-js");
let user = null;
const TokenHistory = (props) => {

    const history = useHistory();

    const [baseUrl, setBaseUrl] = useState('');
    const [isEmptyHistory, setIsEmptyHistory] = useState(false);
    const [histories, setHistories] = useState([]);
    const {t} = useTranslation();
    user = JSON.parse(localStorage.getItem(Constants.USER_PROFILE));

    useEffect(() => {

        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText === null || cipherText.trim() === '') {
            history.push({
                pathname: "/login"
            });
            return;
        }
        let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
        let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        setBaseUrl(decrypt.API_URL);
    }, [TokenHistory]);

    useEffect(() => {
        getAllHistories();
    }, [baseUrl]);

    function getAllHistories() {
        if (`${baseUrl}`.length <= 0) return;
        let userId = user.id;
        setIsEmptyHistory(false);

        axios({
            method: "GET",
            url: `${baseUrl}` + Constants.APPOINTMENT + Constants.OLD_RESERVATIONS + Constants.USER + '/' + userId + '/0/10',
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            setHistories(result.data.data);
            setIsEmptyHistory(result.data.data === null || result.data.data.length === 0)
        }).catch((error) => {
            console.log("data fetching error : " + error)
        })

    }

    return (
        <div>
            {/* Begin page content */}
            <main className='flex-shrink-1 main d-flex align-content-between flex-wrap'>

                {/* Header */}
                <Header/>

                <div className="main-container vh-90 no-scroll">
                    {/* <!-- page content start --> */}
                    <div className="container">
                        <h6 className="my-4 text-center">{t('tokenHistory.header')}</h6>
                        <div className="card-body-scroller">

                            {isEmptyHistory ? (
                                    <div className="text-center mx-auto w-75">
                                        <div className="row align-content-center vh-40">
                                            <div className="col-12">
                                                <figure className="avatar avatar-60 m-1">
                                                    <div className="background"
                                                         style={{backgroundImage: ("img/live-token-icon.png")}}>
                                                        <img src="img/live-token-icon.png" alt=" "/>
                                                    </div>
                                                </figure>
                                            </div>
                                            <div className="col-12">
                                                <h5 className="text-secondary">{t('tokenHistory.emptyData')}</h5>
                                                <p className="text-secondary text-wrap">{t('tokenHistory.emptyData1')}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className="card border-0 mx-3 mt-1 mb-0">
                                        {histories.map((his, i) => (
                                            <div className="card-body" key={i}>
                                                <div className="row align-items-center">
                                                    <div className="col-2 mx-0 px-0 text-center">
                                                        <div className="avatar avatar-30 rounded">
                                                            <div className="background tokenHistoryIcon">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-8 mx-0 px-0 align-self-center">
                                                        <h6 className="mb-0">{his.categoryByCategoryId.name}</h6>
                                                        <p className="small text-secondary">{his.categoryByCategoryId.branchByBranchId.name}<span
                                                            className="px-1">{his.issuedDate}</span><span>{his.issuedTime}</span>
                                                        </p>


                                                    </div>
                                                    {his.status === 1
                                                        ?

                                                        <div className="col-2 px-0 text-center">
                                                            <div className="avatar avatar-40 border-0 text-info">
                                                                <i className="material-icons" title="serving">sync</i>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div/>
                                                    }

                                                    {his.status === 2 ?
                                                        <div className="col-2 px-0 text-center">
                                                            <div className="avatar avatar-40 border-0 text-danger">
                                                                <i className="material-icons"
                                                                   title="noshow">no_accounts</i>
                                                            </div>
                                                        </div>

                                                        :
                                                        <div/>

                                                    }

                                                    {his.status === 3 ?

                                                        <div className="col-2 px-0 text-center">
                                                            <div className="avatar avatar-40 border-0 text-dark">
                                                                <i className="material-icons"
                                                                   title="waiting">airline_seat_recline_extra</i>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div/>


                                                    }

                                                    {his.status === 4 ?
                                                        <div className="col-2 px-0 text-center">
                                                            <div className="avatar avatar-40 border-0 text-success">
                                                                <i className="material-icons"
                                                                   title="served">check_circle</i>
                                                            </div>
                                                        </div>

                                                        :
                                                        <div/>

                                                    }

                                                    {his.status === 99 ?
                                                        <div className="col-2 px-0 text-center">
                                                            <div className="avatar avatar-40 border-0 text-danger">
                                                                <i className="material-icons"
                                                                   title="cancelled">cancel</i>
                                                            </div>
                                                        </div>

                                                        :
                                                        <div/>

                                                    }


                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            }


                        </div>
                    </div>
                </div>

            </main>

            <Footer/>
        </div>
    )
};

export default TokenHistory