import React, {useEffect, useState} from 'react'
import * as Constants from "../util/Constant";

const MyTokenTop = ({liveTicketDetails}) => {

    const [title, setTitle] = useState({});
    useEffect(() => {


        fetch(Constants.CONFIG_TITLE_PATH).then(response => {

            response.json().then(title => {
                setTitle(title);
            }).catch(error => {
                console.error(error);
            })
        });
    }, [MyTokenTop]);

    return (
        <div className="card-header border-0">

            <div className="row">
                <div
                    className="text-center text-lg-right text-sm-left col-2 col-md-1 col-lg-1 align-self-center mx-0 px-0">
                    <div className="avatar avatar-40 shadow-sm rounded-circle ml-2">
                        <figure className="m-0 background"
                                style={{backgroundImage: `url('${title.logo}')`}}
                        >
                            {/* <img src={title.logo} alt=""/> */}
                        </figure>
                    </div>
                </div>
                <div
                    className="text-left col-6 col-md-9 col-lg-9 align-self-center pl-1 text-white">
                    <h6 className="mb-0" style={{width: 'max-content'}}>
                        {title.title1}
                        Ticket Title
                    </h6>
                    <p className="mb-0">
                        {/* {liveTicketDetails.categoryByCategoryId.branchByBranchId.name} */}
                        {liveTicketDetails.branchName}
                    </p>
                </div>
            </div>
        </div>

    )
};
export default MyTokenTop;
