import React, {useEffect, useState,} from 'react';
import Swal from 'sweetalert2';
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import MyTokenTop from "./MyTokenTop";
import axios from 'axios';
import * as Constants from "../util/Constant";

let CryptoJS = require("crypto-js");
const PendingTicket = ({
                           liveTicketDetails, ticketOtherDetails, getLiveTicketDetails, tokenResponse, wfs, liveTokenData, addedTicketData,
                           categoryName, userContact, userTokenNo, servedTicketNumber, waitingCount, estimatedTime, branchName
                       }) => {

    const history = useHistory();

    const [showAppoitmentCancelModal, setShowAppoitmentCancelModal] = useState(false);
    const { t } = useTranslation();

    const [addedData, setaddedData] = useState(null)
    const [tokenData, setTokenData] = useState(null)

    const [baseUrl, setBaseUrl] = useState('');
    const [genToken, setGenToken] = useState(null);

    // get base url
    useEffect(() => {

        let cipherText = localStorage.getItem(Constants.CONFIG);
        let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
        let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        setBaseUrl(decrypt.API_URL);
        setGenToken(decrypt.GEN_TOKEN);

    }, [PendingTicket]);

    useEffect(() => {
        if (liveTicketDetails) {
            console.log(liveTicketDetails);
        }
    }, [liveTicketDetails])


    //open modal
    function openAppointmentCancelModal() {
        if (Object.keys(liveTicketDetails).length === 0) {
            swtAlert('error', t('pending.alert1'))
        } else {
            setShowAppoitmentCancelModal(prev => !prev);
        }
    }

    const cancelAppointment = (ticketId) => {
        Swal.fire({
            position: 'center',
            text: t('appointment.alert5'),
            allowOutsideClick: false,
            width: "200px"
        });
        Swal.showLoading();

        console.log(`${baseUrl}/ticket/${ticketId}/cancel`);

        axios({
            method: 'POST',
            url: `${baseUrl}/ticket/${ticketId}/cancel`,
            headers: {
                Authorization: genToken
            }
        }).then((result) => {
            Swal.close();

            swtAlert('success', null);

            history.push('/get-token')

        }).catch((error) => {
            Swal.close();
            console.log(error);

            swtAlert('error', t('appointment.alert3'));
        })
    }

    // alert - function
    function swtAlert(icon, text) {
        Swal.fire({
            position: 'center',
            icon: icon,
            text: text,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: t('pending.alert2'),
            width: '400px',
        })
    }


    return (


        <>
            <div className="main-container position-fixed vh-90 no-scroll">
                <div className="container pt-4 text-center">

                    <h6 className="mb-3 fw600">{t('token.header')}</h6>

                    <div className="row mx-3">
                        <div className="col-12 px-0">
                            <div className="card my-token-top-area slide-up">

                                <MyTokenTop
                                    liveTicketDetails={liveTicketDetails}
                                    // branchName={liveTicketDetails.branchName}
                                />
                                <div
                                    className="card-body bg-white border-0 rounded-top rounded-bottom vh-50 text-center pb-5">
                                    <div className="container">
                                        <div className="row">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={wfs !== null ? 'container' : 'no-scroll container'}>
                <div className="col-12 " style={{ top: "12em", zIndex: 999 }}>
                    <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                        <div className="card bg-transparent shadow-none border-0 text-center pb-2 fade-on-scroll">
                            <div className="container ">
                                <div className="row mb-3">
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.department')}</h6>
                                        <h6>
                                            {/* {addedData.category.name} */}
                                            {liveTicketDetails.categoryName}
                                        </h6>
                                    </div>
                                    <div className="col-6 align-self-center ">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.contact')}</h6>
                                        {
                                            liveTicketDetails.userContact != null ? (
                                                <h6>
                                                    {liveTicketDetails.userContact}
                                                </h6>
                                            ) : (
                                                <h6>
                                                    Mobile Skipped
                                                </h6>
                                            )
                                        }

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.token')}</h6>
                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.estimatedTime')}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <h1 className="text-green fw700">

                                            {liveTicketDetails.tokenNumber}
                                        </h1>
                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h5 className="fw500">
                                            {/* {ticketOtherDetails.avgServingTime} */}
                                            {liveTicketDetails.est}

                                        </h5>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        {/* <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.servingNo')}</h6> */}
                                        <h6 className="text-secondary text-lable fw600 mb-0">Last serving/served
                                            token</h6>
                                        {/* {ticketOtherDetails.nowServing === null ? (
                                            <h5 className="text-primary fw700">{t('pending.notAvailable')}</h5>
                                        ) : (
                                            <h5 className="text-primary fw700">{ticketOtherDetails.nowServing}</h5>
                                        )} */}
                                        <h5 className="text-primary fw700">
                                            {liveTicketDetails.lastToken != null ? (
                                                liveTicketDetails.lastToken
                                            ) : <>Not served yet</>}
                                            
                                        </h5>
                                    </div>
                                    <div className="col-6 align-self-center">
                                        <h6 className="text-secondary text-lable fw600 mb-0">{t('pending.watings')}</h6>
                                        <h5 className="text-primary fw700">
                                            {/* {ticketOtherDetails.waitingTickets > 0 ?
                                                ticketOtherDetails.waitingTickets - 1 : ticketOtherDetails.waitingTickets} */}

                                            {liveTicketDetails.waitingCount}
                                        </h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center align-self-center">
                                        <button className="my-4 btn btn-danger"
                                            // data-toggle="modal" data-target="#alertModal"
                                                onClick={() => cancelAppointment(liveTicketDetails.token)}
                                        >{t('pending.cancel')}
                                        </button>
                                        <p className="text-danger fw600 p-4 d-none">

                                            {/* {t('pending.recalled')} */}
                                            recalled
                                            <br/>
                                            {/* {t('pending.wait')} */}
                                            wait
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <Workflow tokenResponse={tokenResponse} wfs={wfs} setWorkFlows={setWorkFlows} /> */}

                </div>
            </div>

            <div
                // className={showAppoitmentCancelModal ? 'modal-outside' : ''}
            ></div>

            {/* <CancelAppointmentModal showAppoitmentCancelModal={showAppoitmentCancelModal}
                setShowAppoitmentCancelModal={setShowAppoitmentCancelModal}
                ticketId={liveTicketDetails.ticketId} getLiveTicketDetails={getLiveTicketDetails} /> */}

        </>

    )
};

export default PendingTicket;