import React from 'react'

const NotFound = () => {
    return (
        <div>
            {/* Begin page content */}
            <main className='flex-shrink-1 main d-flex align-content-between flex-wrap'>


                <div className="main-container vh-90 no-scroll">
                    {/* <!-- page content start --> */}
                    <div className="container text-center">
                        <div className="card slide-up">
                            <div className="card-body">
                                <div className="container">
                                    <div className="row vh-90 pb-5 justify-content-center">
                                        <div className="align-self-center w-75">
                                            <div className="row justify-content-center">
                                                <figure className="avatar avatar-150 m-1" style={{width: '300px'}}>
                                                    <div className="background">
                                                        <img src={"img/http404.jpg"} alt="" style={{width: '100%'}}/>
                                                    </div>
                                                </figure>
                                            </div>

                                            <p style={{
                                                color: "gray",
                                                fontSize: '18px',
                                            }}>The Page You're Looking for Was Not Found ! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>


        </div>
    )
}

export default NotFound


