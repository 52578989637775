import i18n from "i18next";

import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import * as Constants from "../util/Constant";
// import LanguageDetector from 'i18next-browser-languagedetector';


// Importing translation files


//Creating object with the variables of imported translation files


//i18N Initialization
let lng = localStorage.getItem(Constants.LANG);
i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: {
            useSuspense: true
        },
        fallbackLng: 'en',
        whitelist: ['en', 'si', 'ta'],
        debug: false,
        lng: lng === null || lng.trim() === '' ? 'en' : lng, //default language
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/lang/{{lng}}Translation.json`
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;