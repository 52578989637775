import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNotification} from 'react-hook-notification';
import * as Constants from "../util/Constant";
import {useHistory} from 'react-router-dom';

let user = null;
let CryptoJS = require("crypto-js");
const Header = (props) => {
    const history = useHistory();
    const [baseUrl, setBaseUrl] = useState('');
    const [title, setTitle] = useState({});
    let lang = localStorage.getItem(Constants.LANG);
    const [language, setLanguage] = useState(lang == null ? 'en' : lang);

    //Calling t and i18n method from useTranslation hook
    const {t, i18n} = useTranslation();

    const notification = useNotification();


    const [isEmptyNotification, setIsEmptyNotification] = useState(false);
    const [notifications, setNotifications] = useState([]);

    let prof = localStorage.getItem(Constants.USER_PROFILE);
    user = JSON.parse(prof);


    // get base url
    useEffect(() => {
        let cipherText = localStorage.getItem(Constants.CONFIG);
        if (cipherText == null) {
            fetch(Constants.CONFIG_PATH).then(response => {
                response.json().then(settings => {
                    localStorage.setItem(Constants.CONFIG, settings.cipher);
                    let bytes = CryptoJS.AES.decrypt(settings.cipher, Constants.ENCRYPT_KEY);
                    let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
                    setBaseUrl(decrypt.API_URL);
                }).catch(error => {
                    console.error(error);
                })
            });
        } else {
            let bytes = CryptoJS.AES.decrypt(cipherText, Constants.ENCRYPT_KEY);
            let decrypt = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            setBaseUrl(decrypt.API_URL);
        }
        fetch(Constants.CONFIG_TITLE_PATH).then(response => {
            response.json().then(title => {
                setTitle(title);
            }).catch(error => {
                console.error(error);
            })
        });
    }, [Header]);

    useEffect(() => {
        if (prof !== null) getAllNotifications();
    }, [baseUrl]);


    //Creating a method to change the language onChnage from select box
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        localStorage.setItem(Constants.LANG, lng);

        if (user !== null && user !== undefined) {
            axios({
                method: "POST",
                url: `${baseUrl}` + Constants.SETTINGS + Constants.CHANGE_LANG + Constants.USER
                    + "/" + user.id + Constants.LANGUAGE + "/" + `${language}`,
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                    'Content-Type': 'application/json'
                }
            }).then((result) => {
                if (result.data.message === 'success') {
                } else {
                    console.log("error : " + result.data.data);
                }
            }).catch((error) => {
                console.log("data fetching error : " + error)
            })
        }


        // window.location.reload();
    };

    const showNotification = (count) => {
        notification.warning({
            title: t('header.check'),
            text: t('header.have') + "( " + (count) + " )" + t('header.notifications'),
            // theme: 'colored | light | dark',
            // transition: 'bounce | flip | fade | slide | zoom',
        })
    };

    const getAllNotifications = () => {
        if (prof === null || baseUrl === '') return;
        setIsEmptyNotification(false);
        let userId = user.id;
        axios({
            method: "GET",
            url: `${baseUrl}` + Constants.NOTIFICATION + Constants.LIST + '/' + userId + '/0/10',
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem(Constants.TOKEN),
                'Content-Type': 'application/json'
            }
        }).then((result) => {
            if (result.data.message === 'success') {
                let notificationData = result.data.data;
                if (notificationData.length === 0) {
                    setIsEmptyNotification(true);
                } else {
                    setIsEmptyNotification(false);
                    setNotifications(result.data.data);
                    showNotification(notificationData.length);
                }
            } else {
                console.log("error : " + result.data.data);
            }


        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem(Constants.MOBILE);
                localStorage.removeItem(Constants.TOKEN);
                localStorage.removeItem(Constants.USER_PROFILE);
                history.push({
                    pathname: '/'
                });
                return;
            }
            console.log("data fetching error : " + error)
        })

    };

    return (

        <header className="header">
            <div className="row">
                <div
                    className="text-center text-lg-right text-sm-left col-2 col-md-1 col-lg-1 align-self-center mx-0 px-0">
                    <div className="avatar avatar-40 shadow-sm rounded-circle ml-2">
                        <figure className="m-0 background" style={{backgroundImage: `url('${title.logo}')`}}>
                            <img src={title.logo} className='header-logo'/>
                        </figure>
                    </div>
                </div>
                <div className="text-left col-6 col-md-9 col-lg-9 align-self-center pl-1">
                    <div className="navbar-brand" href="#">
                        <h6 className="mb-0">{title.title1}</h6>
                        <p className="small mb-0">{title.title2}</p>
                    </div>
                </div>

                <div className="ml-auto col-auto pl-0 align-self-center">
                    <button className="btn-circle lan-select-btn" type="button" data-toggle="dropdown">
                        <span>{language === 'si' ? 'සිං' : language === 'ta' ? 'தா' : 'En'}</span>
                    </button>
                    <div
                        className="mt-3 mr-2 ml-0 dropdown-menu dropdown-menu-left dropdown-language text-center">
                        <button type="button" className="mb-2 btn-circle btn-outline-primary" id="en"
                                onClick={() => changeLanguage('en')}>En
                        </button>
                        <button type="button" className="mb-2 btn-circle btn-outline-primary" id="si"
                                onClick={() => changeLanguage('si')}>සිං
                        </button>
                        <button type="button" className="mb-2 btn-circle btn-outline-primary" id="ta"
                                onClick={() => changeLanguage('ta')}>தா
                        </button>
                    </div>
                </div>

                {prof === null
                    ? <></>
                    :
                    <>
                        <div className="ml-0 col-2 col-md-1 col-lg-1 align-self-center">
                            <button className="btn btn-40 btn-link" type="button" data-toggle="dropdown">
                                <span className="material-icons"> notifications_none </span>
                                <span className="counter notification-count-bg-color">
                                    <span className="count">{notifications.length}</span>
                                </span>
                            </button>
                            <div className=" mt-3 mr-2 ml-0 dropdown-menu dropdown-menu-right dropdown-language"
                                 style={{
                                     width: '350px',
                                     overflowX: 'hidden',
                                     position: 'relative',
                                     left: '-100px',
                                     borderRadius: '0px',
                                     maxHeight: '200px',
                                     overflowY: 'auto'
                                 }}>

                                {/* notification header */}
                                <div className="head1 text-light1 text-center" style={{backgroundColor: 'unset'}}>
                                    <span>{t('header.notification')} ({notifications.length})</span>
                                </div>

                                <ul className="dropdown-menu1" style={{display: 'block', width: '100%', margin: '0px'}}>

                                    {isEmptyNotification ?

                                        (
                                            <li className="notification-box1 bg-gray1 text-center">
                                                <div>
                                                    {t('header.empty')}
                                                </div>
                                            </li>
                                        ) :


                                        (
                                            <div className="card">
                                                {notifications.map((notis, i) => (
                                                    <a href="#" style={{fontSize: '14px', textOverflow: 'ellipsis'}}>
                                                        <li className="notification-box1 bg-gray1" key={notis.id}>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-sm-2 col-2 text-center">
                                                                    <span className="material-icons" style={{
                                                                        fontSize: '23px',
                                                                        lineHeight: '1.0',
                                                                        marginTop: '5px',
                                                                        marginLeft: '10px'
                                                                    }}> notifications_none </span>
                                                                </div>
                                                                <div>

                                                                    <div className="text-dark">
                                                                        {notis.message}
                                                                        {/*<span>{notis.booking.issuedDate}</span>*/}
                                                                    </div>
                                                                    {/*<span className="text-warning">{date.toLocaleTimeString(notis.addedTime)}</span>*/}
                                                                    <small
                                                                        className="text-default">{notis.booking.categoryByCategoryId.name}&nbsp;
                                                                        <span>{notis.booking.issuedDate}</span> &nbsp;
                                                                        <span>{notis.booking.issuedTime}</span></small>


                                                                </div>
                                                            </div>
                                                        </li>
                                                    </a>
                                                ))}
                                            </div>
                                        )


                                    }

                                    {/*<li className="footer1 text-center">*/}
                                    {/*<a href="" className="text-light">View All</a>*/}
                                    {/*</li>*/}
                                </ul>

                            </div>
                        </div>


                    </>}

                
            </div>
        </header>
    )
};

export default Header