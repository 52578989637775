import React from 'react'
import {Link} from "react-router-dom";
import * as Constants from "../util/Constant";
import {useTranslation} from "react-i18next";

let CryptoJS = require("crypto-js");
const Footer = ({ liveTicketDetails }) => {

    let prof = localStorage.getItem(Constants.USER_PROFILE);
    const { t } = useTranslation();

    return (
        <>
            {prof === null ? <></> :

                <div className="footer">
                    <div className="row no-gutters justify-content-center">
                        <div className="col-auto">
                            {liveTicketDetails != null ? (
                                <>
                                    {liveTicketDetails.statusController === 4 ? (
                                        <Link to="/get-token">
                                            <div className="footer-icon-box">
                                                <div className="avatar avatar-25">
                                                    <div className="footer-icons">
                                                        <img style={customStyles} src="img/home-icon.png" alt={''} />
                                                    </div>
                                                </div>
                                                <p>{t('footer.home')}</p>
                                            </div>
                                        </Link>
                                    ) : (
                                        <Link>
                                            <div className="footer-icon-box">
                                                <div className="avatar avatar-25">
                                                    <div className="footer-icons">
                                                        <img style={customStyles} src="img/home-icon.png" alt={''} />
                                                    </div>
                                                </div>
                                                <p>{t('footer.home')}</p>
                                            </div>
                                        </Link>
                                    )}
                                </>
                            ) : (
                                <Link to="/get-token">
                                    <div className="footer-icon-box">
                                        <div className="avatar avatar-25">
                                            <div className="footer-icons">
                                                <img style={customStyles} src="img/home-icon.png" alt={''} />
                                            </div>
                                        </div>
                                        <p>{t('footer.home')}</p>
                                    </div>
                                </Link>
                            )}

                        </div>
                        <div className="col-auto">
                            <Link to="/token-history">
                                <div className="footer-icon-box">
                                    <div className="avatar avatar-25">
                                        <div className="footer-icons">
                                            <img style={customStyles} src="img/token-history-icon.png" alt={''} />
                                        </div>
                                    </div>
                                    <p>{t('footer.history')}</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-auto">
                            <Link to="/edit-profile">
                                <div className="footer-icon-box">
                                    <div className="avatar avatar-25">
                                        <div className="footer-icons">
                                            <img style={customStyles} src="img/profile-icon.png" alt={''} />
                                        </div>
                                    </div>
                                    <p>{t('footer.profile')}</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </>

    )
};

const customStyles = {

    width: "25px",
    height: "25px",
};

export default Footer